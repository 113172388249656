import { useTranslation } from 'react-i18next';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  Selection,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import ScrollView from 'devextreme-react/scroll-view';
import '../../../assets/css/devexpress/dx.common.css';
import '../../../assets/css/devexpress/dx.material.blue.light.css';
import { useEffect, useState } from 'react';
import { HorizontalLine } from 'devextreme-react/chart';
import WaitingSpinner from '../../../components/WaitingSpinner';
import useAppApiContext from '../../../context/api/useAppApiContext';
import Layout from '../../Layouts';
import useAuth from '../../../features/hooks/useAuth';

function CariHesapList() {
  const { t } = useTranslation();
  const { GetCustomerList, GetSubeList } = useAppApiContext();
  const [data, setData] = useState('');
  const [subeList, setSubeList] = useState(0);
  const [sube, setSube] = useState(0);
  const [tipi] = useState(0);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    async function getDataSubeList() {
      const result = await GetSubeList();
      setSubeList(result);
    }
    if (!subeList) {
      getDataSubeList();
    }
    return () => {
      setSubeList('');
    };
  }, []);

  useEffect(() => {
    async function getData(_Sube, _Tipi) {
      const result = await GetCustomerList({ Sube: _Sube, Tipi: _Tipi });
      setData(result);
    }
    if (isAuthenticated) {
      getData(sube, tipi);
    }
    return () => {
      setData('');
    };
  }, [sube, tipi]);

  function selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  // Removed unused renderGridCell function

  if (data) {
    return (
      <Layout>
        <ScrollView direction="horizontal">
          <div className="row gap-2">
            <label className="floating-label">{t('Sube')}</label>
            <select
              className="form-select form-select-lg"
              onChange={(event) => setSube(event.target.value)}
              name="sube"
              required
              value={sube}>
              <option value={0}> {t('TumSubeler')} </option>
              {(subeList?.length &&
                subeList.map((sube) => {
                  return (
                    <option value={sube.code} key={sube.code}>
                      {sube.name}
                    </option>
                  );
                })) ||
                ''}
            </select>
          </div>
          <DataGrid
            id="siparisListesi"
            dataSource={data}
            allowColumnReordering={false}
            scrollingEnabled={false}
            // defaultSelectedRowKeys={0}
            columnAutoWidth={true}
            focusedRowEnabled={true}
            keyExpr="kayitNo"
            onSelectionChanged={(e) => selectionChanged(e)}
            // onContentReady={(e) => contentReady(e)}
          >
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={false} />
            <FilterRow visible={true} />
            <Selection mode={'none'} />
            <HeaderFilter visible={false} />
            <Scrolling preloadEnabled={true} mode={HorizontalLine} />
            <Column
              caption={t('KayitNo')}
              dataField={'kayitNo'}
              allowSorting={false}
              allowFiltering={true}
              allowGrouping={false}
              allowReordering={false}
              width={0}
            />
            <Column caption={t('Kodu')} dataField={'kodu'} width={150} />
            <Column caption={t('Adi')} dataField={'unvani'} width={200} />
            <Column
              caption={t('Borc')}
              dataField={'borc'}
              format="#,##0.00"
              width={120}
            />
            <Column
              caption={t('Alacak')}
              dataField={'alacak'}
              format="#,##0.00"
              width={100}
            />
            <Column
              caption={t('Bakiye')}
              dataField={'bakiye'}
              format="#,##0.00"
              width={150}
            />
            <Column caption={t('Telefon')} dataField={'telefon'} width={125} />
            <Column caption={t('Sehir')} dataField={'sehir'} width={150} />
            <Column caption={t('Email')} dataField={'email'} width={150} />
            <Column caption={t('Adres')} dataField={'adres'} width={150} />

            <Summary>
              <TotalItem column="unvani" summaryType="count" />
              <TotalItem
                column="bakiye"
                summaryType="sum"
                valueFormat="#,###,##0.00"
              />
            </Summary>
            <Pager
              allowedPageSizes={[100, 200, 500, 1000]}
              showPageSizeSelector={true}
            />
            <Paging defaultPageSize={100} />
            {/* <MasterDetail
              enabled={true}
              render={(e) => <SiparisDetail data={e} subeList={subeList} />}
            /> */}
          </DataGrid>
        </ScrollView>
      </Layout>
    );
  } else {
    return <WaitingSpinner data={data} />;
  }
}

export default CariHesapList;
