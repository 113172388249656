import { useState, createContext, useEffect } from 'react';
import useAuth from '../features/hooks/useAuth';
export const MenuContext = createContext({});
import { useTranslation } from 'react-i18next';
import Branch from '../content/Branch';
import Kampanya from '../content/Kampanya';
import About from '../content/pages/About';
import Fatura from '../content/pages/Fatura';
import {
  CariHesapList,
  SiparisGiris,
  SiparisListesi
} from '../content/Siparis';
import PlatformPage from '../content/PlatformPage';
import SiparisOnay from '../content/Siparis/SiparisOnay';
import { CariHesapHareketFoyu } from '../content/CariHesap';

export const MenuProvider = ({ children }) => {
  const [currentMenus, setCurrentMenus] = useState([]);
  const [SelectedComponentId, setSelectedComponentId] = useState('');
  const { t } = useTranslation();

  const selectedComponentHandler = (value) => {
    setSelectedComponentId(value);
  };

  const findSelectedComponentByUsingId = (id) => {
    currentMenus.forEach((menu) => {
      if (menu.id === id) {
        setSelectedComponentId(currentMenus.indexOf(menu));
      }
    });
  };

  const { user } = useAuth();

  let CurrentComponent = '';

  const menuItems = {
    Admin: [
      {
        id: 6,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'home-outline',
        isShowMenu: true
      },
      {
        id: 9,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: false
      },
      {
        id: 10,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 11,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Customer: [
      {
        id: 0,
        to: '/order/Add',
        name: t('YeniSiparis'),
        component: SiparisGiris,
        icon: 'add-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/order/List',
        name: t('SiparisListesi'),
        component: SiparisListesi,
        icon: 'reorder-four-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/order/Approve',
        name: t('SiparisOnay'),
        component: SiparisOnay,
        icon: 'thumbs-up-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/customer/ActionList',
        name: t('MusteriHesabi'),
        component: CariHesapHareketFoyu,
        icon: 'calculator-outline',
        isShowMenu: true
      },
      {
        id: 4,
        to: '/platforms',
        name: t('Platforms'),
        component: PlatformPage,
        icon: 'cart-outline',
        isShowMenu: true
      },
      {
        id: 4,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'home-outline',
        isShowMenu: false
      },
      {
        id: 5,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: false
      },
      {
        id: 7,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Unknown: [
      {
        id: 1,
        to: '/order/List',
        name: t('SiparisListesi'),
        component: SiparisListesi,
        icon: 'reorder-four-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/order/Approve',
        name: t('SiparisOnay'),
        component: SiparisOnay,
        icon: 'thumbs-up-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/customer/ActionList',
        name: t('MusteriHesabi'),
        component: CariHesapHareketFoyu,
        icon: 'calculator-outline',
        isShowMenu: true
      },
      {
        id: 4,
        to: '/platforms',
        name: t('Platforms'),
        component: PlatformPage,
        icon: 'cart-outline',
        isShowMenu: true
      },
      {
        id: 4,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'home-outline',
        isShowMenu: false
      },
      {
        id: 5,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: false
      },
      {
        id: 7,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    Page: [
      {
        id: 0,
        to: '/order/Add',
        name: t('YeniSiparis'),
        component: SiparisGiris,
        icon: 'add-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/order/List',
        name: t('SiparisListesi'),
        component: SiparisListesi,
        icon: 'reorder-four-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/order/Approve',
        name: t('SiparisOnay'),
        component: SiparisOnay,
        icon: 'thumbs-up-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/customer/ActionList',
        name: t('MusteriHesabi'),
        component: CariHesapHareketFoyu,
        icon: 'calculator-outline',
        isShowMenu: true
      },
      {
        id: 4,
        to: '/platforms',
        name: t('Platforms'),
        component: PlatformPage,
        icon: 'cart-outline',
        isShowMenu: true
      },
      {
        id: 4,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'home-outline',
        isShowMenu: false
      },
      {
        id: 5,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: false
      },
      {
        id: 7,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    UnknownEski: [
      {
        id: 1,
        to: '/platforms',
        name: t('Platforms'),
        component: PlatformPage,
        icon: 'cart-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'home-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 5,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      }
    ],
    PlatformAdmin: [
      {
        id: 0,
        to: '/order/Add',
        name: t('YeniSiparis'),
        component: SiparisGiris,
        icon: 'add-outline',
        isShowMenu: true
      },
      {
        id: 1,
        to: '/order/List',
        name: t('SiparisListesi'),
        component: SiparisListesi,
        icon: 'reorder-four-outline',
        isShowMenu: true
      },
      {
        id: 2,
        to: '/order/CariHesapList',
        name: t('MusteriListesi'),
        component: CariHesapList,
        icon: 'people-outline',
        isShowMenu: true
      },
      {
        id: 3,
        to: '/branch',
        name: t('SubeListesi'),
        component: Branch,
        icon: 'home-outline',
        isShowMenu: true
      },
      {
        id: 4,
        to: '/Kampanya',
        name: t('Kampanya'),
        component: Kampanya,
        icon: 'gift-outline',
        isShowMenu: true
      },
      {
        id: 5,
        to: '/fatura',
        name: t('FaturaBilgileri'),
        component: Fatura,
        icon: 'reader-outline',
        isShowMenu: false
      },
      {
        id: 6,
        to: '/about',
        name: t('Hakkinda'),
        component: About,
        icon: 'information-outline',
        isShowMenu: false
      },
      {
        id: 7,
        to: '/platforms',
        name: t('Platforms'),
        component: PlatformPage,
        icon: 'cart-outline',
        isShowMenu: false
      },
      {
        id: 8,
        to: '/customer/ActionList',
        name: t('MusteriHesabi'),
        component: CariHesapHareketFoyu,
        icon: 'calculator-outline',
        isShowMenu: true
      }
    ]
  };

  useEffect(() => {
    if (user) {
      const { authenticationProviderType } = user;
      setCurrentMenus(menuItems[authenticationProviderType]);
    }
  }, [user]);

  if (SelectedComponentId) {
    CurrentComponent = currentMenus[SelectedComponentId]['component'];
  } else {
    CurrentComponent = '';
  }

  return (
    <MenuContext.Provider
      value={{
        currentMenus,
        SelectedComponentId,
        CurrentComponent,
        selectedComponentHandler,
        findSelectedComponentByUsingId
      }}>
      {children}
    </MenuContext.Provider>
  );
};
