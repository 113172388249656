import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGeneralContext from '../../../context/api/useGeneralContext';
import useOrderContext from '../../../context/api/useOrderContext';
import './siparisguncelle.css';

function SiparisGuncelle({
  isShowUpdatePage,
  setIsShowUpdatePage,
  detailData
}) {
  const { t } = useTranslation();
  const { SetOrderUpdate } = useOrderContext();
  const { GetSupplier } = useGeneralContext();
  const [setFirmalar] = useState([]);
  const [price, setPrice] = useState(detailData.tutar);
  const [miktar, setMiktar] = useState(detailData.miktar);
  const [tutar, setTutar] = useState(detailData.tutar);
  const [currency] = useState(detailData.kur);
  const [total, setTotal] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(detailData.nakliyeFiyati);

  useEffect(() => {
    GetSupplier().then((data) => {
      setFirmalar(data);
    });
  }, []);

  useEffect(() => {
    setTutar(parseFloat(price * miktar).toFixed(2));
  }, [price, miktar]);

  useEffect(() => {
    setTotal(
      parseFloat(
        parseFloat(tutar / currency) + parseFloat(deliveryPrice)
      ).toFixed(2)
    );
  }, [tutar, currency, deliveryPrice]);

  const fields = {
    Alıcı: detailData.cariUnvani,
    Renk: detailData.renk,
    Beden: detailData.beden,
    TerminTarihi: detailData.terminTarihi,
    Marka: detailData.marka,
    UrunAdi: detailData.stokAdi
  };

  const [inputFields, setInputFields] = useState(fields);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setInputFields({ ...inputFields, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = Object.values(inputFields).every((value) => {
      return String(value).length > 0;
    });

    if (isValid) {
      const result = await SetOrderUpdate({
        ...detailData,
        Renk: inputFields.Renk,
        Beden: inputFields.Beden,
        TerminTarihi: inputFields.TerminTarihi,
        Marka: inputFields.Marka,
        StokAdi: inputFields.UrunAdi,
        miktar: miktar,
        tutar: tutar,
        birimFiyat: price,
        nakliyeFiyati: deliveryPrice
      });
      if (result) {
        setIsShowUpdatePage(false);
      }
    }
  };

  return (
    <div className={`${isShowUpdatePage ? 'show-child' : ''}`}>
      <div
        className="order-update-gray-layout show-layout"
        onClick={() => setIsShowUpdatePage(false)}></div>
      <div
        className={`container overflow-auto mb-4 my-sm-4 position-fixed top-0 start-0 end-0 bottom-0 bg-white gl-z-popup p-4 rounded order-update-content`}>
        <button
          className="btn-close btn-md order-update-close-btn m-2"
          onClick={() => setIsShowUpdatePage(false)}></button>
        <div className="order-wrapper-update">
          <form onSubmit={(e) => handleSubmit(e)}>
            {Object.keys(inputFields).map((key, index) => {
              return (
                <div className="form-group" key={index}>
                  <label htmlFor={key}>{key}</label>
                  <input
                    type="text"
                    disabled={key === 'Alici'}
                    className={`form-control mb-2`}
                    id={key}
                    name={key}
                    value={inputFields[key]}
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
              );
            })}

            <div className="row">
              <div className="col mb-2">
                <label>{t('Miktar')}</label>
                <input
                  type="number"
                  name="miktar"
                  className="form-control py-2"
                  id="miktar"
                  value={miktar}
                  required
                  onChange={(event) => setMiktar(event.target.value)}
                />
              </div>
              <div className="col mb-2">
                <label>{t('Fiyat')}</label>
                <input
                  type="number"
                  name="price"
                  className="form-control py-2"
                  id="price"
                  value={price}
                  required
                  onChange={(event) => setPrice(event.target.value)}
                />
              </div>
              <div className="col mb-2">
                <label>{t('Tutar')}</label>
                <input
                  type="number"
                  name="tutar"
                  className="form-control py-2"
                  id="tutar"
                  value={tutar}
                  required
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col mb-2">
                <label>{t('KargoUcreti')}</label>
                <input
                  type="number"
                  name="deliveryPrice"
                  className="form-control py-2"
                  id="deliveryPrice"
                  value={deliveryPrice}
                  required
                  onBlur={(event) =>
                    setDeliveryPrice(
                      event.target.value ? event.target.value : 0
                    )
                  }
                  onFocus={(event) =>
                    setDeliveryPrice(
                      event.target.value != 0 ? event.target.value : ''
                    )
                  }
                  onChange={(event) => setDeliveryPrice(event.target.value)}
                />
              </div>
              <div className="col mb-2">
                <label>{t('DovizKuru')}</label>
                <input
                  type="number"
                  name="currency"
                  className="form-control py-2"
                  id="currency"
                  value={currency}
                  required
                  disabled
                />
              </div>
              <div className="col mb-2">
                <label>{t('Toplam')}</label>
                <input
                  type="number"
                  name="total"
                  className="form-control py-2"
                  id="total"
                  value={total}
                  required
                  disabled
                />
              </div>
            </div>
            {/* {image && (
                        <div className="d-flex justify-content-center w-100">
                            <img src={image} width={200} height={300}></img>
                        </div>
                    )} */}
            <button
              type="submit"
              className="btn btn-primary btn-block"
              onClick={(e) => handleSubmit(e)}>
              {t('Kaydet')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
export default SiparisGuncelle;
