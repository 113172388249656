import Layout from '../Layouts';
import i18n from '../../assets/i18n/i18n';
import { useEffect, useState } from 'react';
import useNotificationApiContext from '../../context/api/useNotificationApiContext';
import Iframe from 'react-iframe';

function Kampanya() {
  const [campaignList, setCampaignList] = useState([]);
  const { GetKampanyaList } = useNotificationApiContext();

  useEffect(() => {
    async function getData() {
      const result = await GetKampanyaList();
      setCampaignList(result);
    }
    getData();
    return () => {
      setCampaignList([]);
    };
  }, []);

  return (
    <Layout>
      <div className="container-fluid mb-4">
        <div className="row gx-4">
          {campaignList.map((el) => {
            const adi = el['adi_' + i18n.language]
              ? el['adi_' + i18n.language]
              : el.adi;
            const aciklama = el['aciklama_' + i18n.language]
              ? el['aciklama_' + i18n.language]
              : el.aciklama;
            return (
              <div
                className="col-sm-12 col-md-4 my-2 bg-white rounded-3 gl-card-decorative gl-shadow-bottom"
                key={el.kodu}>
                {el.video ? (
                  <Iframe
                    url={el.image}
                    width="100%"
                    height="450px"
                    allow="fullscreen"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                  />
                ) : (
                  <img src={el.image} className="img-fluid"></img>
                )}
                <h4 className="text-dark mt-2 mb-1">{adi}</h4>
                <p className="text-secondary mb-2">{aciklama}</p>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
export default Kampanya;
