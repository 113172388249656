import { useTranslation } from 'react-i18next';
import { DataGrid } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Selection,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import '../../../assets/css/devexpress/dx.common.css';
import '../../../assets/css/devexpress/dx.material.blue.light.css';
import { useEffect, useState } from 'react';
import useDate from '../../../features/hooks/useDate';
import WaitingSpinner from '../../../components/WaitingSpinner';
import useAppApiContext from '../../../context/api/useAppApiContext';
import CariHesapHareketDetail from './CariHesapHareketDetail';
import Layout from '../../Layouts';
function CariHesapFoyu() {
  const { t } = useTranslation();

  const { GetCustomerActionList } = useAppApiContext();

  const [data, setData] = useState('');

  const { ilkTarih, sonTarih } = useDate();

  useEffect(() => {
    async function getData() {
      const result = await GetCustomerActionList(ilkTarih, sonTarih);
      setData(result);
    }
    if (!data) {
      getData();
    }
    return () => {
      setData('');
    };
  }, []);

  function selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  if (data) {
    return (
      <Layout>
        <ScrollView direction="horizontal">
          <DataGrid
            dataSource={data}
            allowColumnReordering={false}
            scrollingEnabled={false}
            // defaultSelectedRowKeys={0}
            columnAutoWidth={true}
            focusedRowEnabled={true}
            keyExpr="cha_Kayitno"
            onSelectionChanged={(e) => selectionChanged(e)}
            // onContentReady={(e) => contentReady(e)}
          >
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={true} />
            <FilterRow visible={true} />
            <Selection mode={'none'} />
            <HeaderFilter visible={true} />
            <Column
              caption={t('Tarih')}
              dataField={'cha_Tarihi'}
              dataType={'date'}
              format={'dd.MM.yyyy'}
              width={110}
            />
            <Column
              caption={t('Borc')}
              dataField={'cha_BorcOrj'}
              format="#,###,##0.00"
              width={150}
            />
            <Column
              caption={t('Alacak')}
              dataField={'cha_AlacakOrj'}
              format="#,###,##0.00"
              width={150}
            />
            <Column
              caption={t('Bakiye')}
              dataField={'cha_Bakiye_Orj'}
              format="#,###,##0.00"
              width={150}
            />
            <Column
              caption={t('Aciklama')}
              dataField={'cha_Aciklama1'}
              dataType={'string'}
              width={125}
            />
            <Column
              caption={t('EvrakTipi')}
              dataField={'cha_EvrakTipi'}
              dataType={'string'}
              width={125}
            />
            <Column
              caption={t('Tipi')}
              dataField={'cha_Tipi'}
              dataType={'string'}
              width={125}
            />
            <Column
              caption={t('SiparisNo')}
              dataField={'cha_Malk_Evrakno'}
              dataType={'string'}
              width={125}
            />
            <Column
              caption={t('KayitNo')}
              dataField={'cha_Kayitno'}
              allowSorting={false}
              allowFiltering={true}
              allowGrouping={false}
              allowReordering={false}
              width={0}
              visible={false}
            />
            <Summary>
              <TotalItem column="cha_Tarihi" summaryType="count" />
              <TotalItem
                column="cha_BorcOrj"
                summaryType="sum"
                valueFormat="#,###,##0.00"
              />
              <TotalItem
                column="cha_AlacakOrj"
                summaryType="sum"
                valueFormat="#,###,##0.00"
              />
              <TotalItem
                column="cha_Bakiye_Orj"
                summaryType="sum"
                valueFormat="#,###,##0.00"
              />
            </Summary>

            <Pager
              allowedPageSizes={[25, 50, 100, 1000]}
              showPageSizeSelector={true}
            />
            <Paging defaultPageSize={25} />
            <MasterDetail
              enabled={true}
              render={(e) => <CariHesapHareketDetail data={e} />}
            />
          </DataGrid>
        </ScrollView>
      </Layout>
    );
  } else {
    return <WaitingSpinner data={data} />;
  }
}

export default CariHesapFoyu;
