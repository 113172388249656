import { Route, Routes } from 'react-router-dom';
import Auth from './Auth';
import RequireAuth from './RequireAuth';
import { lazy, Suspense } from 'react';
import WaitingSpinner from '../components/WaitingSpinner';
import Siparis from './siparis';
import CariHesap from './carihesap';
import Finans from './finans';
import Balance from '../content/Siparis/Balance';
const Kampanya = lazy(() => import('../content/Kampanya'));
const MainPage = lazy(() => import('../content/MainPage'));
const PlatformPage = lazy(() => import('../content/PlatformPage'));
const Branch = lazy(() => import('../content/Branch'));
const Profile = lazy(() => import('../content/Profile'));
const Dashboard = lazy(() => import('./dashboard'));
const OneriSikayet = lazy(() => import('../content/pages/OneriSikayet'));
const About = lazy(() => import('../content/pages/About'));
const Fatura = lazy(() => import('../content/pages/Fatura'));
const Iletisim = lazy(() => import('../content/pages/iletisim'));

const Router = () => {
  return (
    <>
      <Suspense fallback={<WaitingSpinner />}>
        <Routes>
          <Route path="/auth/*" element={<Auth />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                {' '}
                <Balance />{' '}
              </RequireAuth>
            }
          />
          <Route path="/platforms" element={<PlatformPage />} />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/menu"
            element={
              <RequireAuth>
                <MainPage />
              </RequireAuth>
            }
          />
          <Route
            path="/kampanya"
            element={
              <RequireAuth>
                <Kampanya />
              </RequireAuth>
            }
          />
          <Route
            path="/OneriSikayet"
            element={
              <RequireAuth>
                <OneriSikayet />
              </RequireAuth>
            }
          />
          <Route
            path="/iletisim"
            element={
              <RequireAuth>
                <Iletisim />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/*"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route path="/Branch" element={<Branch />} />
          <Route path="/fatura" element={<Fatura />} />
          <Route path="/About" element={<About />} />
          <Route path="/balance" element={<Balance />} />
          <Route path="/order/*" element={<Siparis />} />
          <Route path="/customer/*" element={<CariHesap />} />
          <Route path="/Finans/*" element={<Finans />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Router;
