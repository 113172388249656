import { useState, useEffect } from 'react';
import Layout from '../Layouts';
import './platformpage.css';
import useOrderContext from '../../context/api/useOrderContext';

function PlatformPage() {
  const { GetPlatformList } = useOrderContext();

  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    async function getData() {
      const result = await GetPlatformList();
      setPlatforms(result);
    }

    getData();

    return () => {
      setPlatforms([]);
    };
  }, []);

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row gx-4">
          {platforms.length > 0 &&
            platforms.map((platform) => {
              return (
                <a
                  href={platform.link}
                  key={platform.code}
                  className="p-1 col-4 col-lg-2 btn text-white gl-fs-6"
                  target="_blank"
                  rel="noreferrer">
                  <div
                    className="mb-0 bg-white text-secondary gl-card-decorative w-100 h-100 rounded shadow gl-place-center p-3 mainpage-grid-container gl-shadow-bottom main-platform-button"
                    style={{
                      backgroundImage: `url(${platform.image})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'auto',
                      backgroundPosition: 'center',
                      backgroundClip: 'border-box'
                    }}>
                    <ion-icon size="large" name={platform.icon}></ion-icon>
                  </div>
                </a>
              );
            })}
        </div>
      </div>
    </Layout>
  );
}

export default PlatformPage;
