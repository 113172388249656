import  { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import DateSelection from '../../../components/DateSelection';
import useNotificationApiContext from '../../../context/api/useNotificationApiContext';
import useAuth from '../../../features/hooks/useAuth';
import useMenu from '../../../features/hooks/useMenu';
import "./header.css"
import ProfileMenu from './ProfileMenu';
import UnreadNotifications from './UnreadNotifications';

export default function Header({ showSidebarHandler, mainToggleClass }) {
    const { t } = useTranslation();

    const rand = Math.floor(Math.random() * 1000) + 1 // for disable caching profile photo

    const { SetNotification } = useNotificationApiContext()

    const { logout, notifications, setNotifications } = useAuth();

    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [isShowNotifications, setIsShowNotifications] = useState(false);

    const showDateSelection = import.meta.env.VITE_APP_DATE_SELECTION;

    const [isShowProfile, setIsShowProfile] = useState(false);

    const [isShowDate, setIsShowDate] = useState(false);

    const { user } = useAuth();

    let scaleClass = "";

    isShowProfile ? scaleClass = "scale-100" : "";
    const { selectedComponentHandler } = useMenu();
    function handleDateClick() {
        setIsShowDate(!isShowDate);
    }

    function handleNotificationsClick() {
        setIsShowNotifications(!isShowNotifications);
    }

    function close() {
        setIsShowProfile(false);
    }

    useEffect(() => {
        const newUnreadNotifications = notifications.filter(notification => !notification.status);
        setUnreadNotifications(newUnreadNotifications);
    }, [notifications])

    async function setNotificationsClick() {
        handleNotificationsClick();
        const result = await SetNotification();
        setNotifications(result);
    }

    return (
        <header>
            <nav className={`nav position-relative gl-shadow-decorative-nav ${mainToggleClass}`}>
                <button onClick={() => showSidebarHandler(true)} className='btn p-2 ms-2 me-2 d-xl-none text-secondary'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="nav--hamburger" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
                <button className='btn p-0 d-lg-none'>
                    <NavLink to={"/"} className='d-block w-100 flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                        <img src="/images/icons/logo.png" width={48} height={48} className="rounded-circle "></img>

                    </NavLink>

                </button>
                {showDateSelection == "true" && <DateSelection isShowDate={isShowDate} handleDateClick={handleDateClick}></DateSelection>}
                <UnreadNotifications notifications={notifications} isShowNotifications={isShowNotifications} setNotificationsClick={setNotificationsClick}></UnreadNotifications>

                <div className='d-flex align-items-center gap-sm-2 ms-lg-auto'>
                    {showDateSelection == "true" &&
                        <button type="button" className="btn position-relative btn-notification text-secondary" onClick={() => handleDateClick()}>
                            <ion-icon name="calendar-outline" size="large"></ion-icon>
                        </button>
                    }

                    <button type="button" className="btn position-relative btn-notification text-secondary" onClick={() => handleNotificationsClick()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                        {
                            unreadNotifications.length != 0 &&
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {unreadNotifications.length}
                                <span className="visually-hidden">{t('OkunmamisMesajlar')}</span>
                            </span>
                        }
                    </button>
                    {/* onBlur={close} */}
                    <div className='btn btn-user'>
                        <img src={user?.image ? user.image + "&version=" + rand : "/images/avatar/UserNull.png"} width={32} height={32} className="rounded-circle" onClick={() => setIsShowProfile(!isShowProfile)}></img>
                        <ProfileMenu scaleClass={scaleClass} setIsShowProfile={setIsShowProfile} />
                    </div>
                </div>
            </nav>
        </header >
    );
}
