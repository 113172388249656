import { t } from 'i18next';
import { useEffect, useState } from 'react';
import useGeneralContext from '../../../context/api/useGeneralContext';
import useAuth from '../../../features/hooks/useAuth';
import Layout from '../../Layouts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useRoles from '../../../features/helpers/isAdmin';

export default function Balance() {
  const [searchParams] = useSearchParams();
  const DeviceToken = searchParams.get('Token');
  const ApiVersion = searchParams.get('ApiVersion');
  const Platform = searchParams.get('Platform');
  const { isUnknown } = useRoles();

  if (DeviceToken != null) {
    if (
      localStorage.getItem('DeviceToken') == null ||
      localStorage.getItem('DeviceToken') != DeviceToken
    ) {
      localStorage.setItem('DeviceToken', DeviceToken);
      localStorage.setItem('ApiVersion', ApiVersion);
      localStorage.setItem('Platform', Platform);
    }
  }

  const [data, setData] = useState({ 0: [], 1: [], 2: [] });
  const { GetOrderBalance } = useGeneralContext();
  const { user } = useAuth();
  let navigate = useNavigate();
  useEffect(() => {
    const getBalance = async () => {
      const response = await GetOrderBalance();
      if (response) {
        setData(() => {
          const newData = { 0: [], 1: [], 2: [] };

          response.forEach((element) => {
            newData[element.tipi].push(element);
          });

          return newData;
        });
      }
    };
    if (user) {
      getBalance();
    }
  }, [user]);

  return (
    <Layout>
      <div className="container-fluid">
        <section>
          <div className="row">
            {data['0'].map((item, index) => {
              const balance = item.bakiye;
              const adi = item.adi;
              return (
                <div className="col-xl-6 col-md-12 mb-4" key={index}>
                  <button
                    className="btn card w-100"
                    onClick={() => navigate('/customer/ActionList')}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between p-md-1">
                        <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                          <div
                            className={`text-success d-flex gap-1 ${
                              balance < 0 && 'text-danger'
                            }`}>
                            <ion-icon
                              name="card-outline"
                              size="large"></ion-icon>
                            <h2 className={`h1 mb-0 me-4`}>
                              $ {balance.toFixed(2)}
                            </h2>
                          </div>
                          <p className="mb-0 ms-auto text-muted h3">{t(adi)}</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
          <div className="row">
            <div className="col-xl-6 col-md-12 mb-4">
              <div className="card">
                <div className="card-body rounded-3">
                  {data['1'].map((item, index) => {
                    const balance = item.bakiye;
                    const adi = item.adi;
                    const img = item.img;
                    return (
                      <div
                        onClick={() => navigate('/order/list')}
                        key={index}
                        className="d-flex justify-content-between align-items-center
                    mb-3 border py-2 px-2 border-info rounded-3 rounded">
                        <span className="d-flex text-primary">
                          <ion-icon name={img} size="large"></ion-icon>
                        </span>
                        <p className="mb-0 ms-1 me-auto text-muted">{t(adi)}</p>
                        <h2 className="h2 mb-0">{balance}</h2>
                      </div>
                    );
                  })}

                  {data['2'].map((item, index) => {
                    const { bakiye, adi, img, kodu } = item;
                    return (
                      <div
                        key={index}
                        onClick={() =>
                          navigate('/order/list', {
                            state: {
                              statusCode: kodu
                            }
                          })
                        }
                        className="d-flex justify-content-between align-items-center
                    mb-3 border py-2 px-2 border-info rounded-3 rounded">
                        <span className="d-flex text-primary">
                          <ion-icon name={img} size="large"></ion-icon>
                        </span>
                        <p className="mb-0 ms-1 me-auto text-muted">
                          {t(adi)}
                          {/* {t(adi)} */}
                        </p>
                        <h2 className="h2 mb-0">{bakiye}</h2>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        {!isUnknown ? (
          <div className="row">
            <div className="col-xl-6 col-md-12 mb-4">
              <button
                className="btn card w-100"
                onClick={() => navigate('/order/Add')}>
                <div className="card-body">
                  <div className="d-flex justify-content-between p-md-1">
                    <div className="d-flex flex-row justify-content-between align-items-center gap-5">
                      <ion-icon name="add-outline" size="large"></ion-icon>
                      <p className="mb-0 ms-auto text-muted h6">
                        {' '}
                        {t('YeniSiparis')}
                      </p>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
}
