export const faturaBilgileri =
{
    "Unvan": "My Mobile Store",
    "VergiDairesi": "",
    "VergiNumarası": "",
    "Adres": "istanbul, Turkey",
    "Telefon": "+90 000 000 00 00",
}





