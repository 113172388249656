import { Route, Routes } from 'react-router-dom';
import CariHesapLimit from '../content/Finans/CariHesapLimit';

const Finans = () => {
  return (
    <Routes>
      <Route path="/" element={<CariHesapLimit />}>
        <Route path="CariHesapLimit" element={<CariHesapLimit />} />
      </Route>
    </Routes>
  );
};

export default Finans;
