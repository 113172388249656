import { useEffect, useState } from 'react';
import WaitingSpinner from '../../../components/WaitingSpinner';
import { useTranslation } from 'react-i18next';
import OrderStatusTracking from '../OrderStatusTracking';
import { Table } from 'react-bootstrap';
import SiparisGuncelle from './SiparisGuncelle';
import DialogConfirm from '../../../components/DialogConfirm';
import useOrderContext from '../../../context/api/useOrderContext';
import useRoles from '../../../features/helpers/isAdmin';

export default function SiparisDetail({ data, statusList }) {
  const [isShowUpdatePage, setIsShowUpdatePage] = useState(false);
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [dialogResult, setDialogResult] = useState(false);
  const { SetOrderStatus } = useOrderContext();
  const detailData = data.data;
  const [status, setStatus] = useState(detailData.durum);
  const { t } = useTranslation();
  const { isAdmin } = useRoles();

  console.log('detailData');
  console.log(detailData);

  useEffect(() => {
    if (dialogResult) {
      setIsShowConfirmDialog(false);
      setDialogResult(false);
      SetOrderStatus({ Guid: detailData.guid, Status: status });
    }
  }, [dialogResult]);

  if (detailData) {
    console.log(detailData);
    return (
      <>
        <OrderStatusTracking data={detailData.durum} />
        <div className="bg-white">
          <Table striped hover>
            <tbody>
              <tr>
                <td width={100}>{t('UrunAdi')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.stokAdi}</td>
              </tr>
              <tr>
                <td width={100}>{t('Marka')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.marka}</td>
              </tr>
              <tr>
                <td width={100}>{t('Beden')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.beden}</td>
              </tr>
              <tr>
                <td width={100}>{t('Renk')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.renk}</td>
              </tr>
              <tr>
                <td width={100}>{t('Fiyat')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.birimFiyat}</td>
              </tr>
              <tr>
                <td width={100}>{t('Tutar')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.tutar}</td>
              </tr>
              <tr>
                <td width={100}>{t('Resim')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">
                  <img
                    className="img-fluid img-detail"
                    src={detailData.img}></img>
                </td>
              </tr>
              <tr>
                <td width={100}>{t('KayitNo')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.kayitNo}</td>
              </tr>
              <tr>
                <td width={100}>{t('Link')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">
                  <a href={detailData.link} target="_blank" rel="noreferrer">
                    {detailData.link}
                  </a>
                </td>
              </tr>
              <tr>
                <td width={100}>{t('Aciklama')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.aciklama}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        {isAdmin && (
          <div className="d-flex justify-flex-start gap-3 mb-2">
            {/* İşlem 19 */}
            <select
              className=""
              onChange={(event) => {
                setStatus(event.target.value);
                setIsShowConfirmDialog(true);
              }}
              name="status"
              required
              value={status}>
              {(statusList?.length &&
                statusList.map((status) => {
                  return (
                    <option value={status.code} key={status.code}>
                      {status.name}
                    </option>
                  );
                })) ||
                ''}
            </select>
            <DialogConfirm
              isShowConfirmDialog={isShowConfirmDialog}
              setIsShowConfirmDialog={setIsShowConfirmDialog}
              setDialogResult={setDialogResult}
            />
            <button
              className="btn btn-danger at-btn-min-width fw-bold"
              onClick={() => setIsShowUpdatePage(true)}>
              {t('Duzenle')}
            </button>
            <SiparisGuncelle
              isShowUpdatePage={isShowUpdatePage}
              detailData={detailData}
              setIsShowUpdatePage={setIsShowUpdatePage}
            />
          </div>
        )}
      </>
    );
  } else {
    return <WaitingSpinner data={detailData} />;
  }
}
