const krJSON = {
  Tamam: 'باشە',
  Tarih: 'بەروار',
  Bugun: 'ئەمڕۆ',
  BuHafta: ' ئەم هەفتەیە',
  BuAy: 'ئەم مانگە',
  BuYil: 'ئەمساڵ',
  SifreEslesmiyor: 'ژمارەی نهێنی هەڵەیە',
  SifreDegistir: 'گۆڕینی ژمارەی نهێنی',
  MevcutSifre: 'ژمارەی نهێنی ',
  YeniSifre: 'ژمارەی نهێنی نوێ ',
  SifreyiDogrula: 'دووبارە کردنەوەی ژمارەی نهێنی',
  Kaydet: 'پاراستن',
  Geri: 'گەڕانەوە',
  SifreTelefonunuzaGonderildi: 'ژمارەی نهێنی بۆ مۆبایلەکەت هات ',
  SifrenizDahaOnceGonderildi: 'پێشتر ژامرەی نهێنیت وەرگرتووە',
  SifremiUnuttum: 'ژمارەی نهێنیم لەبیرکردووە',
  KullaniciAdi: 'ناوی بەکارهێنەر',
  TelefonVeyaEmail: 'ژمارەی مۆبایل یاخود ئیمەیل',
  Sifirla: 'نوێکردنەوە',
  SifremVar: 'ژمارەی نهێنیم هەیە',
  Giris: 'جونەژوورەوە',
  GeciciSifre: 'ژمارەی نهێنی کاتی',
  Sifre: 'ژمارەی نهێنی',
  KayitOl: 'خۆتۆمارکردن',
  GecersizSifre: 'ژمارەی نهێنی هەڵەیە',
  AdiSoyadi: 'ناوی تەواو',
  Email: 'ئیمەیل',
  Gsm: 'ژمارەی مۆبایل',
  UyeOl: 'خۆتۆمارکردن',
  DahaOnceKayitli: 'هەژمارم هەیە',
  Telefon1: 'ژمارە مۆبایلی 1',
  Telefon2: 'ژمارە مۆبایلی 2',
  Adres: 'ناونیشان',
  Sehir: 'شار',
  Ulke: 'وڵات',
  Instagram: ' ئنستاگرام',
  Facebook: ' فەیسبوک',
  Baslik: 'ناونیشان',
  Sira: 'ڕێکخستن',
  EvrakTipi: 'جۆری دۆکومێنت',
  Cinsi: 'جۆر',
  Tipi: 'جۆر',
  AracNo: 'ژمارە سەیارە',
  Note: 'تێبینی',
  Aciklama: 'رونکردنەوە',
  Aciklama1: 'رونکردنەوە 1',
  Aciklama2: 'رونکردنەوە 2',
  Borc: 'قەرز',
  Alacak: 'وەرگیراو',
  Bakiye: 'ماوە',
  Depo: ' کۆگا',
  TeslimNoktasi: 'شوێنی وەرگرتنەوە',
  SiparisNo: 'ژمارەی داواکاری',
  KayitNo: 'ژمارەی تۆمارکردن',
  Bekleyen: ' چاوەروانکردن',
  Detay: 'وردەکاری',
  Yol: ' ڕێگا',
  Saha: 'مەیدان',
  Kapi: 'دەروازە',
  Biten: ' کۆتای هاتن',
  TarihTutar: 'بەروار و بڕ',
  AraToplam: 'کۆی گشتی',
  GonderilenHavale: 'نێردەر',
  KapSayisi: 'ژمارەی پارچە',
  Kg: 'کیلۆ گرام',
  M3: 'م³',
  M3Kg: 'م³/کیلۆگرام',
  CariHesapLimiti: 'سنوری هەژمار',
  Kodu: 'کۆد',
  Arama: 'گەڕان',
  Adi: 'ناو',
  Soyadi: 'ناو',
  Vade: 'ماوە',
  Toplam: ' گشتی',
  Doviz: 'دراو',
  AnaSayfa: 'لاپەڕەی سەرەکی',
  Menu: 'مینۆ',
  Platforms: 'بازاڕی',
  Kampanya: 'داشکاندن',
  Takipci: ' چاودێری',
  Sube: 'لق',
  Tr: 'تورکی',
  En: 'ئینگلیزی',
  De: 'ئەڵمانی',
  Ar: 'عەرەبی',
  Kr: 'کوردی',
  OkunmamisMesajlar: 'نەخوێندراوەکان',
  Bildirimler: 'ئاگادارکردنەوەکان',
  TumunuGoruntule: 'پیشاندان',
  Hakkinda: 'دەربارە',
  FaturaBilgileri: 'زانیاری فاتورە',
  iletisim: 'پەیوەندی',
  OneriSikayet: 'پێشنیار',
  Degistir: 'گۆڕین',
  GecersizTelefon: 'ژمارە تەلەفۆن هەڵە',
  Telefon: 'ژمارە تەلەفۆن',
  TelefonNumarasi: 'ژمارە تەلەفۆن" ',
  TumSubeler: 'هەموو لقەکان',
  Unvani: 'ناو',
  LutfenSehirSeciniz: 'تکایە شارەکەت هەڵبژێرە',
  MusteriSayfasi: 'لاپەرەی بەکارهێنەر',
  Link: 'لینک',
  BedenSecin: 'سایز',
  TekBeden: 'یەک سایز',
  Variant: 'ڤاریانت',
  Beden: 'سایز',
  Renk: 'ڕەنگ',
  UrunAdi: 'ناوی بەرهەم',
  Miktar: 'دانە(بڕ)',
  Fiyat: 'نرخ دانە',
  Tutar: 'گشتی',
  TutarUsd: 'گشتی دۆلار',
  DovizKuru: 'گۆڕینەوەی دراو',
  TeslimTarihi: 'بەرواری ناردن',
  Resim: 'وێنە',
  Durum: 'بارودۆخ',
  Kargo: 'کارگۆ',
  Firma: 'کۆمپانیا',
  Marka: 'براند',
  KargoUcreti: 'گواستنەوە',
  GumrukUcreti: 'باجی دەوڵەت',
  Duzenle: 'گۆڕانکاری',
  Sil: 'سڕینەوە',
  Onay: 'قبوڵ کردن',
  SubeListesi: 'لیستی لقەکانمان',
  SiparisListesi: 'لیستی داواکاری',
  YeniSiparis: 'داواکاری نوێ',
  SiparisOnay: 'قبوڵکردنی داواکاری',
  MusteriHesapListesi: 'لیستی هەژماری بەکارهێنەر',
  MusteriListesi: 'لیستی باکارهێنەر',
  TeslimEdildi: 'وەرگیراو',
  MusteriHesabi: 'هەژماری بەکارهێنەر',
  Devir: 'التحويل',
  _Devir_: 'التحويل',
  AlisFaturasi: 'فاتورەی کڕین',
  SatisFaturasi: 'فاتورەی کڕین',
  Fatura: 'فاتورە',
  Tahsilat: 'پارەدانان لە هەژمار',
  Odeme: 'پارەدان لە هەژمار',
  Masraf: 'خەرجی',
  Cari: 'مشتەری',
  CariPersonel: 'هەژماری کارمەند',
  Kasa: 'خەزێنە',
  Banka: 'بانک',
  Hizmet: 'خزمەتگوزاری',
  MuhasebeHesabı: 'هەژماری ژمێریار',
  Personel: 'کارمەند',
  Demirbas: 'جێگیرکردن',
  İthalat: 'هاوردە',
  Leasing: 'کرێ',
  Kredi: 'قەرز',
  Stok: 'ستۆک',
  TumSiparisler: 'هەموو داواکاریەکان',
  Beklemede: 'جاوەروانی',
  KontrolEdiliyor: 'پشکنین',
  MusteriOnayinda: 'قبوڵکردنی داواکاری',
  MusteriIptali: 'رەتکردنەوەی مشتەری',
  Onaylandi: 'پەسەندکرا',
  Tedarik: 'ئۆردەرکرا',
  Hazirlaniyor: 'ئامادەدەکرێت',
  TurkiyeDepo: 'کۆگای تورکیا',
  YurtDisiKargo: 'گواستنەوەی نێودەوڵەتی عێراق',
  Iptal: 'کانسڵ',
  Red: 'رەتکردنەوە',
  Iade: 'گەڕاندنەوە',
  HasarliUrun: 'شکان خراپبوون',
  Subede: 'گەیشتوو',
  Tahakkuk: 'پارەدان'
};

export default krJSON;
