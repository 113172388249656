import Layout from '../../Layouts';

function About() {
  return (
    <Layout>
      <div>
        <h3>TOP MODERN COMPANY</h3>
        <p>
          Top Modern Company for General Trading, International Transportation,
          Tourism and Aviation/Limited Our company was established in 2018 and
          is headquartered in Sulaymaniyah and has branches in several other
          Iraq cities. now everyone benefits from the service we offer our
          citizens through your mobile phone you can buy all the goods that are
          not available in Iraq with us shopping services and shipping to you
          from all websites and brands around the world. Our company aims to
          benefit all our citizens inside and outside Iraq by providing all the
          necessary materials and goods that are difficult to obtain in
          Kurdistan and Iraq, helping our businessmen and factories and online
          shopping websites who need to transport their goods from Turkey to All
          Iraq with high international standards of service. During our years of
          work inside Iraq, we have been able to help peoples to set up their
          own businesses, ranging from an online sales page to companies and
          factories by reaching out to find their raw materials and equipment
          abroad Iraq. In the field of tourism and aviation, we have tried to
          help businessmen and people who go abroad for business purposes in
          providing experts, translators, accommodation, transportation and
          payment of their transactions. In the field of online shopping
          websites, we have made great efforts to develop applications in this
          field in building trust between buyers and sellers and guarantee their
          payments to provide of their rights to transactions.
        </p>
      </div>
    </Layout>
  );
}

export default About;
