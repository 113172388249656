import useMessage from '@/features/hooks/useMessage';
import apiClient from '@/utils/axios';

export default function useOrderContext() {
  const { messageBox } = useMessage();

  const GetPlatformData = async (
    platform = null,
    Link = null,
    id = null,
    itemNumber = null
  ) => {
    try {
      const response = await apiClient.get(
        `/api/siparis/GetPlatformData?platform=${platform}&Link=${Link}&id=${id}&itemNumber=${itemNumber}`
      );
      if (response.data) {
        return response.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
      // const data = response.data.data[0];
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const SetOrderInsert = async (data) => {
    try {
      const response = await apiClient.post(
        `/api/Siparis/SetOrderInsert`,
        data
      );
      if (response.data.data[0].sonuc) {
        messageBox('Başarılı', 'success');
        return response.data.data[0].sonuc;
      } else {
        if (response.data.data[0].message) {
          messageBox(response.data.data[0].message, 'error');
        } else {
          messageBox('Hata Oluştu', 'error');
        }
      }
      // const data = response.data.data[0];
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const SetOrderUpdate = async (data) => {
    try {
      const response = await apiClient.post(
        `/api/Siparis/SetOrderUpdate`,
        data
      );
      if (response.data.data[0].sonuc) {
        messageBox('Başarılı', 'success');
        return response.data.data[0].sonuc;
      } else {
        if (response.data.data[0].message) {
          messageBox(response.data.data[0].message, 'error');
        } else {
          messageBox('Hata Oluştu', 'error');
        }
      }
      // const data = response.data.data[0];
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const SetOrderStatus = async (data) => {
    try {
      const response = await apiClient.post(
        `/api/Siparis/SetOrderStatus`,
        data
      );
      if (response.data.data[0].sonuc) {
        messageBox('Başarılı', 'success');
        return response.data.data[0].sonuc;
      } else {
        if (response.data.data[0].message) {
          messageBox(response.data.data[0].message, 'error');
        } else {
          messageBox('Hata Oluştu', 'error');
        }
      }
      // const data = response.data.data[0];
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetOrderByID = async (ID) => {
    try {
      const response = await apiClient.get(
        `/api/Siparis/GetOrderByID?ID=${ID}`
      );
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetOrderByGuidID = async (GuidID) => {
    try {
      const response = await apiClient.get(
        `/api/Siparis/GetOrderByGuidID?GuidID=${GuidID}`
      );
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetOrderByNo = async (OrderNo) => {
    try {
      const response = await apiClient.get(
        `/api/Siparis/GetOrderByNo?OrderNo=${OrderNo}`
      );
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetOrderList = async (IlkTarih, SonTarih, Status) => {
    try {
      const response = await apiClient.get(
        `/api/Siparis/GetOrderList?IlkTarih=${IlkTarih}&SonTarih=${SonTarih}&Status=${Status}`
      );
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetOrderByCustomerCode = async (Code) => {
    try {
      const response = await apiClient.get(
        `/api/Siparis/GetOrderByCustomerCode?Code=${Code}`
      );
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetOrderByUserName = async () => {
    try {
      const response = await apiClient.get(`/api/Siparis/GetOrderByUserName`);
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetOrderApprovaList = async () => {
    try {
      const response = await apiClient.get(`/api/Siparis/GetOrderApprovaList`);
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetOrderStatusDefinitionList = async () => {
    try {
      const response = await apiClient.get(
        `/api/Siparis/GetOrderStatusDefinitionList`
      );
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetCustomerOrderStatusDefinitionList = async () => {
    try {
      const response = await apiClient.get(
        `/api/Siparis/GetCustomerOrderStatusDefinitionList`
      );
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetOrderStatusContentList = async (Status) => {
    try {
      const response = await apiClient.get(
        `/api/Siparis/GetOrderStatusContentList?Status=${Status}`
      );
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetPlatformList = async () => {
    try {
      const response = await apiClient.get(`/api/Siparis/GetPlatformList`);
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  return {
    GetOrderList,
    GetOrderApprovaList,
    GetOrderByNo,
    GetOrderByID,
    GetOrderByGuidID,
    GetOrderByCustomerCode,
    GetOrderByUserName,
    SetOrderInsert,
    SetOrderStatus,
    GetPlatformData,
    GetOrderStatusDefinitionList,
    GetCustomerOrderStatusDefinitionList,
    GetOrderStatusContentList,
    SetOrderUpdate,
    GetPlatformList
  };
}
