const TrenyolLinkToItemCode = (link) => {
  let sonuc = '';
  try {
    let index1 = link.search('-p-') + 3;
    let linkEnd = link.slice(index1);
    let i = 0;
    while (i < linkEnd.length) {
      let deger = linkEnd[i];
      if (deger >= 0 && deger <= 9) {
        sonuc = sonuc + deger;
      } else {
        break;
      }
      i++;
    }
    return sonuc;
  } catch (err) {
    sonuc = '';
  }
};
const TrenyolLinkToContentID = (link) => {
  let sonuc = '';
  try {
    let index1 = link.search('ContentId=') + 10;
    let linkEnd = link.slice(index1);
    let i = 0;
    while (i < linkEnd.length) {
      let deger = linkEnd[i];
      if (deger >= 0 && deger <= 9) {
        sonuc = sonuc + deger;
      } else {
        break;
      }
      i++;
    }
    return sonuc;
  } catch (err) {
    sonuc = '';
  }
};

const LinkToPlatformName = (link) => {
  let result = '';
  try {
    let trendyol = link.search('trendyol.com');
    let hepsiburada = link.search('hepsiburada.com');
    let zara = link.search('zara.com');
    let hm = link.search('hm.com');
    let bershka = link.search('bershka.com');
    let mango = link.search('mango.com');
    let stradivarius = link.search('stradivarius.com');
    let pullandbear = link.search('pullandbear.com');
    let lcwaikiki = link.search('lcwaikiki.com');
    let uspoloassn = link.search('uspoloassn.com');
    let dolap = link.search('dolap.com/');
    let englishhome = link.search('englishhome.com');
    let karaca = link.search('karaca.com/');
    let modasena = link.search('modasena.com');
    let flo = link.search('flo.com');
    let ikea = link.search('ikea.com');
    let lacoste = link.search('lacoste.com');
    let koton = link.search('koton.com');
    let akakce = link.search('akakce.com');
    let boyner = link.search('boyner.com');
    let nike = link.search('nike.com');
    let zarahome = link.search('zarahome.com');
    let adidas = link.search('adidas.com');
    let sefamerve = link.search('sefamerve.com');
    let massimodutti = link.search('massimodutti.com');
    let morhipo = link.search('morhipo.com');

    if (trendyol > 0) {
      result = 'trendyol';
    } else if (hepsiburada > 0) {
      result = 'hepsiburada';
    } else if (zara > 0) {
      result = 'zara';
    } else if (morhipo > 0) {
      result = 'morhipo';
    } else if (bershka > 0) {
      result = 'bershka';
    } else if (mango > 0) {
      result = 'mango';
    } else if (hm > 0) {
      result = 'hm';
    } else if (stradivarius > 0) {
      result = 'stradivarius';
    } else if (pullandbear > 0) {
      result = 'pullandbear';
    } else if (lcwaikiki > 0) {
      result = 'lcwaikiki';
    } else if (uspoloassn > 0) {
      result = 'uspoloassn';
    } else if (dolap > 0) {
      result = 'dolap';
    } else if (englishhome > 0) {
      result = 'englishhome';
    } else if (karaca > 0) {
      result = 'karaca';
    } else if (modasena > 0) {
      result = 'modasena';
    } else if (flo > 0) {
      result = 'flo';
    } else if (ikea > 0) {
      result = 'ikea';
    } else if (lacoste > 0) {
      result = 'lacoste';
    } else if (koton > 0) {
      result = 'koton';
    } else if (akakce > 0) {
      result = 'akakce';
    } else if (boyner > 0) {
      result = 'boyner';
    } else if (nike > 0) {
      result = 'nike';
    } else if (zarahome > 0) {
      result = 'zarahome';
    } else if (adidas > 0) {
      result = 'adidas';
    } else if (sefamerve > 0) {
      result = 'sefamerve';
    } else if (massimodutti > 0) {
      result = 'massimodutti';
    } else {
      result = 'trendyol';
    }
    return result;
  } catch (err) {
    result = 'trendyol';
  }
};
export { TrenyolLinkToItemCode, LinkToPlatformName, TrenyolLinkToContentID };
