import { useEffect, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  Scrolling
} from 'devextreme-react/data-grid';
import { HorizontalLine } from 'devextreme-react/chart';
import ScrollView from 'devextreme-react/scroll-view';
// import { branch } from '../../data/branch';
import BranchDetail from './BranchDetail';
import WaitingSpinner from '../../components/WaitingSpinner';
import Layout from '../Layouts';
import { useTranslation } from 'react-i18next';
import useAppApiContext from '../../context/api/useAppApiContext';

export default function Branch() {
  //   const data = branch;
  const { t } = useTranslation();
  const { GetBranchList } = useAppApiContext();
  const [data, setData] = useState('');

  const selectionChanged = (e) => {
    console.log('Selection changed:', e);
  };

  useEffect(() => {
    async function getData() {
      const result = await GetBranchList();
      setData(result);
    }
    getData();
  }, []);

  if (data) {
    return (
      <Layout>
        <ScrollView direction="horizontal">
          <DataGrid
            id="branch"
            dataSource={data}
            allowColumnReordering={false}
            scrollingEnabled={false}
            onSelectionChanged={selectionChanged}
            columnAutoWidth={true}
            focusedRowEnabled={true}
            keyExpr="id"
            // onContentReady={(e) => onContentReady(e)}
          >
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={false} />
            <FilterRow visible={true} />
            {/* <Selection mode={'multiple'} /> */}
            <HeaderFilter visible={true} />
            <Scrolling preloadEnabled={true} mode={HorizontalLine} />

            <Column dataField={'sehir'} caption={t('Sehir')} width={125} />
            <Column caption={t('Baslik')} dataField={'title'} width={300} />
            <Column dataField={'ulke'} caption={t('Ulke')} width={125} />
            <Column dataField={'id'} caption={t('Sira')} width={80} />
            <MasterDetail
              enabled={true}
              render={(e) => <BranchDetail data={e} />}
            />
          </DataGrid>
        </ScrollView>
      </Layout>
    );
  } else {
    return <WaitingSpinner data={data} />;
  }
}
