import { useState, createContext, useEffect } from 'react';
export const DateSelectionContext = createContext({});

export const DateSelectionProvider = ({ children }) => {
  const getCurrentDate = new Date();

  const [ilkTarih, setilkTarih] = useState(
    `${getCurrentDate.getFullYear()}-01-01`
  );

  const [sonTarih, setSonTarih] = useState(
    `${getCurrentDate.getFullYear()}-${
      getCurrentDate.getMonth() + 1
    }-${getCurrentDate.getDate()}`
  );

  useEffect(() => {
    setilkTarih(`${getCurrentDate.getFullYear()}-01-01`);
    setSonTarih(
      `${getCurrentDate.getFullYear()}-${(
        '0' +
        (getCurrentDate.getMonth() + 1)
      ).slice(-2)}-${('0' + getCurrentDate.getDate()).slice(-2)}`
    );
  }, []);

  return (
    <DateSelectionContext.Provider
      value={{ ilkTarih, sonTarih, setilkTarih, setSonTarih }}>
      {children}
    </DateSelectionContext.Provider>
  );
};
