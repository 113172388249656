import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrderStatusTracking from '../OrderStatusTracking';
import { Table } from 'react-bootstrap';
import SiparisGuncelle from './SiparisGuncelle';
import useOrderContext from '@/context/api/useOrderContext';
import DialogConfirm from '@/components/DialogConfirm';
import WaitingSpinner from '@/components/WaitingSpinner';
import './SiparisDetail.css';
import useRoles from '@/features/helpers/isAdmin';

export default function SiparisDetail({ data, statusList }) {
  const [isShowUpdatePage, setIsShowUpdatePage] = useState(false);
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [dialogResult, setDialogResult] = useState(false);
  const { SetOrderStatus } = useOrderContext();
  const detailData = data.data;
  const [status, setStatus] = useState(detailData?.durum || null); // Optional chaining for safety
  const { t } = useTranslation();
  const { isAdmin } = useRoles();

  useEffect(() => {
    async function updateStatus() {
      if (dialogResult) {
        setIsShowConfirmDialog(false);
        setDialogResult(false);
        await SetOrderStatus({ Guid: detailData.guid, Status: status });
      }
    }
    updateStatus();
  }, [dialogResult]);

  if (detailData) {
    return (
      <>
        <OrderStatusTracking data={detailData.durum} />
        <div className="bg-white">
          <Table striped hover>
            <tbody>
              <tr>
                <td width={100}>{t('UrunAdi')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.stokAdi}</td>
              </tr>
              <tr>
                <td width={100}>{t('Marka')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.marka}</td>
              </tr>
              <tr>
                <td width={100}>{t('Beden')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.beden}</td>
              </tr>
              <tr>
                <td width={100}>{t('Renk')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.renk}</td>
              </tr>
              <tr>
                <td width={100}>{t('Miktar')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.miktar}</td>
              </tr>
              <tr>
                <td width={100}>{t('Tutar')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">
                  {detailData.tutarUsd?.toFixed(2) || '0.00'}
                </td>
              </tr>
              <tr>
                <td width={100}>{t('KargoUcreti')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">
                  {detailData.nakliyeFiyati?.toFixed(2) || '0.00'}
                </td>
              </tr>
              <tr>
                <td width={100}>{t('GumrukUcreti')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">
                  {detailData.gumrukFiyati?.toFixed(2) || '0.00'}
                </td>
              </tr>
              <tr>
                <td width={100}>{t('Toplam')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">
                  {detailData.yekun?.toFixed(2) || '0.00'}
                </td>
              </tr>
              <tr>
                <td width={100}>{t('Link')}</td>
                <td width={5}>:</td>
                <td>
                  <a
                    href={detailData.link}
                    target="_blank"
                    rel="noopener noreferrer">
                    {detailData.link}
                  </a>
                </td>
              </tr>
              <tr>
                <td width={100}>{t('KayitNo')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.kayitNo}</td>
              </tr>
              <tr>
                <td width={100}>{t('Aciklama')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.aciklama}</td>
              </tr>
              <tr>
                <td width={100}>{t('Tahakkuk')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.tahakuk}</td>
              </tr>
              <tr>
                <td width={100}>{t('Odeme')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">{detailData.tahsilat}</td>
              </tr>
              <tr>
                <td width={100}>{t('Resim')}</td>
                <td width={5}>:</td>
                <td className="fw-bold">
                  <img
                    className="img-fluid img-detail"
                    src={detailData.img}
                    alt="Ürün resmi"></img>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        {isAdmin && (
          <div className="d-flex justify-flex-start gap-3 mb-2">
            <select
              className=""
              onChange={(event) => {
                setStatus(parseInt(event.target.value));
                setIsShowConfirmDialog(true);
              }}
              name="status"
              required
              value={status}>
              {statusList?.length > 0 &&
                statusList.map((status) => {
                  return (
                    <option value={status.code} key={status.code}>
                      {t(status.name)}
                    </option>
                  );
                })}
            </select>
            <DialogConfirm
              isShowConfirmDialog={isShowConfirmDialog}
              setIsShowConfirmDialog={setIsShowConfirmDialog}
              setDialogResult={setDialogResult}
            />
            <button
              className="btn btn-danger at-btn-min-width fw-bold"
              onClick={() => setIsShowUpdatePage(true)}>
              {t('Duzenle')}
            </button>
            <SiparisGuncelle
              isShowUpdatePage={isShowUpdatePage}
              detailData={detailData}
              setIsShowUpdatePage={setIsShowUpdatePage}
            />
          </div>
        )}
      </>
    );
  } else {
    return <WaitingSpinner data={detailData} />;
  }
}
