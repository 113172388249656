import { createContext } from 'react';
import { useState, useEffect } from 'react';

export const LinkParamsContext = createContext({});
export const LinkParamsProvider = ({ children }) => {
  const [deviceToken, setDeviceToken] = useState('');
  const [apiVersion, setApiVersion] = useState('');
  const [platform, setPlatform] = useState('');

  useEffect(() => {
    if (localStorage.getItem('DeviceToken') != null) {
      setDeviceToken(localStorage.getItem('DeviceToken'));
      setApiVersion(localStorage.getItem('ApiVersion'));
      setPlatform(localStorage.getItem('Platform'));
    }
  }, []);

  return (
    <LinkParamsContext.Provider
      value={{
        deviceToken,
        apiVersion,
        platform
      }}>
      {children}
    </LinkParamsContext.Provider>
  );
};
