import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../features/hooks/useAuth';

export default function RequireAuth({ children }) {
  const [isRedirect, setIsRedirect] = useState(true);
  let { isAuthenticated } = useAuth();

  let location = useLocation();

  useEffect(() => {
    setIsRedirect(isAuthenticated);
  }, []);

  if (!isRedirect) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
}
