const enJSON = {
  Tamam: 'Ok',
  Tarih: 'Date',
  Bugun: 'Today',
  BuHafta: 'This Week',
  BuAy: 'This Month',
  BuYil: 'This Year',
  SifreEslesmiyor: 'Passwords do not match',
  SifreDegistir: 'Change Password',
  MevcutSifre: 'Current Password',
  YeniSifre: 'New Password',
  SifreyiDogrula: 'Confirm Password',
  Kaydet: 'Save',
  Geri: 'Back',
  SifreTelefonunuzaGonderildi: 'Password sent to your phone',
  SifrenizDahaOnceGonderildi: 'Your password has been sent before',
  SifremiUnuttum: 'Forgot Password',
  KullaniciAdi: 'Username',
  TelefonVeyaEmail: 'Phone or Email',
  Sifirla: 'Reset',
  SifremVar: 'I have a password',
  Giris: 'Login',
  GeciciSifre: 'Temporary Password',
  Sifre: 'Password',
  KayitOl: 'Register',
  GecersizTelefon: 'Invalid Phone',
  GecersizSifre: 'Invalid Password',
  AdiSoyadi: 'Full Name',
  Email: 'Email',
  Gsm: 'Mobile',
  UyeOl: 'Sign Up',
  DahaOnceKayitli: 'Already Registered',
  Telefon1: 'Phone 1',
  Telefon2: 'Phone 2',
  Adres: 'Address',
  Sehir: 'City',
  Ulke: 'Country',
  Instagram: 'Instagram',
  Facebook: 'Facebook',
  Baslik: 'Title',
  Sira: 'Order',
  EvrakTipi: 'Document Type',
  Cinsi: 'Type',
  Tipi: 'Type',
  AracNo: 'Vehicle No',
  Note: 'Note',
  Aciklama: 'Description',
  Aciklama1: 'Description 1',
  Aciklama2: 'Description 2',
  Bakiye: 'Balance',
  Depo: 'Warehouse',
  TeslimNoktasi: 'Delivery Point',
  SiparisNo: 'Order No',
  KayitNo: 'Record No',
  Bekleyen: 'Pending',
  Detay: 'Detail',
  Yol: 'Road',
  Saha: 'Field',
  Kapi: 'Door',
  Biten: 'Finished',
  TarihTutar: 'Date Amount',
  AraToplam: 'Subtotal',
  GonderilenHavale: 'Sent Transfer',
  KapSayisi: 'Number of Doors',
  Kg: 'Kg',
  M3: 'm³',
  M3Kg: 'm³/Kg',
  CariHesapLimiti: 'Current Account Limit',
  Kodu: 'Code',
  Arama: 'Search',
  Adi: 'Name',
  Soyadi: 'Surname',
  Vade: 'Due Date',
  Toplam: 'Total',
  Doviz: 'Currency',
  AnaSayfa: 'Home',
  Menu: 'Menu',
  Platforms: 'Platforms',
  Kampanya: 'Campaign',
  Takipci: 'Follower',
  Sube: 'Branch',
  Tr: 'Turkish',
  En: 'English',
  De: 'German',
  Ar: 'Arabic',
  Kr: 'Kurdish',
  OkunmamisMesajlar: 'Unread Messages',
  Bildirimler: 'Notifications',
  TumunuGoruntule: 'View All',
  Hakkinda: 'About',
  FaturaBilgileri: 'Invoice Information',
  iletisim: 'Contact',
  OneriSikayet: 'Suggestions & Complaints',
  Degistir: 'Change',
  Telefon: 'Phone',
  TelefonNumarasi: 'Phone Number',
  TumSubeler: 'All Branches',
  Unvani: 'Title',
  LutfenSehirSeciniz: 'Please Select a City',
  MusteriSayfasi: 'Customer Page',
  Link: 'Link',
  BedenSecin: 'Select Size',
  TekBeden: 'One Size',
  Variant: 'Variant',
  Beden: 'Size',
  Renk: 'Color',
  UrunAdi: 'Product Name',
  Miktar: 'Quantity',
  Fiyat: 'Price',
  Tutar: 'Amount',
  TutarUsd: 'Amount$',
  DovizKuru: 'ExchangeRate',
  Kur: 'Rate',
  TeslimTarihi: 'Delivery Date',
  Resim: 'Image',
  Durum: 'Status',
  Kargo: 'Cargo',
  Firma: 'Company',
  Marka: 'Brand',
  KargoUcreti: 'Shipping Fee',
  GumrukUcreti: 'Customs Fee',
  Duzenle: 'Edit',
  Sil: 'Delete',
  Onay: 'Confirm',
  SubeListesi: 'Branch List',
  SiparisListesi: 'Order List',
  YeniSiparis: 'New Order',
  SiparisOnay: 'Order Confirmation',
  MusteriHesapListesi: 'Customer Account List',
  MusteriListesi: 'Customer List',
  MusteriHesabi: 'Customer Account',
  Devir: 'Transfer',
  _Devir_: 'Transfer',
  AlisFaturasi: 'Purchase Invoice',
  SatisFaturasi: 'Sales Invoice',
  Fatura: 'Invoice',
  Tahsilat: 'Collection',
  Odeme: 'Payment',
  Masraf: 'Expense',
  Alacak: 'Credit',
  Borc: 'Debt',
  Cari: 'Customer',
  CariPersonel: 'Customer Staff',
  Kasa: 'Case',
  Banka: 'Bank',
  Hizmet: 'Service',
  MuhasebeHesabı: 'Accounting Account',
  Personel: 'Staff',
  Demirbas: 'Fixed Asset',
  İthalat: 'Import',
  Leasing: 'Leasing',
  Kredi: 'Credit',
  Stok: 'Stock',
  TumSiparisler: 'All Orders',
  Beklemede: 'Pending',
  KontrolEdiliyor: 'Checking',
  MusteriOnayinda: 'Customer Confirmation',
  MusteriIptali: 'Customer Cancellation',
  Onaylandi: 'Confirmed',
  Tedarik: 'Supply',
  Hazirlaniyor: 'Preparing',
  TurkiyeDepo: 'Turkey Warehouse',
  YurtDisiKargo: 'Abroad Cargo',
  Iptal: 'Cancel',
  Red: 'Reject',
  Iade: 'Return',
  HasarliUrun: 'Damaged Product',
  Subede: 'At Branch',
  TeslimEdildi: 'Delivered',
  Document1: 'Passport',
  Document2: 'ID Card',
  Dikkat: 'Attention',
  Onayliyormusunuz: 'Are you sure?',
  Evet: 'Yes',
  Hayir: 'No',
  Tahakkuk: 'Accrual'
};

export default enJSON;
