import { Route, Routes, Outlet } from 'react-router-dom';
import SiparisOnay from '../content/Siparis/SiparisOnay';
import {
  CariHesapList,
  SiparisGiris,
  SiparisListesi
} from '../content/Siparis';

const Siparis = () => {
  return (
    <Routes>
      <Route path="/" element={<SiparisListesi />} /> {/* Ana rota */}
      <Route path="Add" element={<SiparisGiris />} /> {/* Add rotası */}
      <Route path="List" element={<SiparisListesi />} /> {/* List rotası */}
      <Route path="Approve" element={<SiparisOnay />} /> {/* Approve rotası */}
      <Route path="CariHesapList" element={<CariHesapList />} />{' '}
      {/* CariHesapList rotası */}
    </Routes>
  );
};

export default Siparis;
