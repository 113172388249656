import useAuth from '../hooks/useAuth';

export default function useRoles() {
  const { user } = useAuth();

  const isPlatformAdmin = user?.authenticationProviderType === 'PlatformAdmin';
  const isPage = user?.authenticationProviderType === 'Page';
  const isAdmin =
    user?.authenticationProviderType === 'Admin' ||
    user?.authenticationProviderType === 'PlatformAdmin';
  const isCustomer = user?.authenticationProviderType === 'Customer';
  const isUnknown = user?.authenticationProviderType === 'Unknown';

  return { isPlatformAdmin, isUnknown, isAdmin, isCustomer, isPage };
}
