import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import WaitingSpinner from '../../../components/WaitingSpinner/index'; // Adjust the path as necessary

export default function CariHesapHareketDetail({ data }) {
  const rowData = data.row.data;
  const {
    cha_Sube,
    cha_TeslimNoktasi,
    cha_EvrakTipi,
    cha_Tipi,
    cha_Karsi_Cari_Cinsi,
    cha_BorcOrj,
    cha_AlacakOrj,
    cha_Bakiye_Orj,
    cha_Aciklama1,
    cha_Aciklama2,
    cha_AracNo
  } = rowData;
  const { t } = useTranslation();
  if (rowData) {
    return (
      <>
        <div className="bg-white">
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td width={50}>{t('EvrakTipi')}</td>
                <td width={5}>:</td>
                <td>{t(cha_EvrakTipi)}</td>
              </tr>
              <tr>
                <td width={50}>{t('Cinsi')}</td>
                <td width={5}>:</td>
                <td>{t(cha_Karsi_Cari_Cinsi)}</td>
              </tr>
              <tr>
                <td width={50}>{t('Tipi')}</td>
                <td width={5}>:</td>
                <td>{t(cha_Tipi)}</td>
              </tr>
              <tr>
                <td width={50}>{t('AracNo')}</td>
                <td width={5}>:</td>
                <td>{cha_AracNo}</td>
              </tr>
              <tr>
                <td width={50}>{t('Aciklama1')}</td>
                <td width={5}>:</td>
                <td>{cha_Aciklama1}</td>
              </tr>
              <tr>
                <td width={50}>{t('Aciklama2')}</td>
                <td width={5}>:</td>
                <td>{cha_Aciklama2}</td>
              </tr>

              <tr>
                <td width={50}>{t('Borc')}</td>
                <td width={5}>:</td>
                <td>{cha_BorcOrj.toFixed(2)}</td>
              </tr>
              <tr>
                <td width={50}>{t('Alacak')}</td>
                <td width={5}>:</td>
                <td>{cha_AlacakOrj.toFixed(2)}</td>
              </tr>
              <tr>
                <td width={50}>{t('Bakiye')}</td>
                <td width={5}>:</td>
                <td>{cha_Bakiye_Orj.toFixed(2)}</td>
              </tr>
              <tr>
                <td width={50}>{t('Depo')}</td>
                <td width={5}>:</td>
                <td>{cha_Sube}</td>
              </tr>
              <tr>
                <td width={50}>{t('TeslimNoktasi')}</td>
                <td width={5}>:</td>
                <td>{cha_TeslimNoktasi}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    );
  } else {
    return <WaitingSpinner data={data} />;
  }
}
