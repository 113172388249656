import { Route, Routes } from 'react-router-dom';
import { CariHesapHareketFoyu } from '../content/CariHesap';
// import SiparisOnay from '../content/Siparis/SiparisOnay';

const carihesap = () => {
  return (
    <Routes>
      <Route path="/" element={<CariHesapHareketFoyu />}>
        <Route path="ActionList" element={<CariHesapHareketFoyu />} />
      </Route>
    </Routes>
  );
};

export default carihesap;
