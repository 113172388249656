import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGeneralContext from '../../../context/api/useGeneralContext';
import useOrderContext from '../../../context/api/useOrderContext';
import useMessage from '../../../features/hooks/useMessage';
import Layout from '../../Layouts';
import CustomerCreatePage from './CustomerCreatePage';
import './siparisGiris.css';
import {
  TrenyolLinkToItemCode,
  LinkToPlatformName,
  TrenyolLinkToContentID
} from '../../../utils/StringOperation';
import UploadPhoto from './UploadPhoto';
import useAuth from '../../../features/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import DialogConfirm from '../../../components/DialogConfirm';

function SiparisGiris() {
  const { t } = useTranslation();
  const { GetPlatformData, SetOrderInsert } = useOrderContext();
  const { GetCustomerByName, GetSupplier, GetCurrency } = useGeneralContext();
  const [customerCode, setCustomerCode] = useState('');
  const [costumerNotFound, setCostumerNotFound] = useState(false);
  const [product, setProduct] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [linkButtonDisebled, setLinkButtonDisebled] = useState(false);

  const [firmalar, setFirmalar] = useState([]);
  const [price, setPrice] = useState(0);
  const [miktar, setMiktar] = useState(1);
  const [tutar, setTutar] = useState(0);
  const [tutarUsd, setTutarUsd] = useState(0);
  const [currency, setCurrency] = useState(0);
  const [country, setCountry] = useState('TRY');
  const [total, setTotal] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const { messageBox } = useMessage();
  const [platform, setPlatform] = useState('trendyol');
  const [linkAdress, setLinkAdress] = useState('');
  const { user } = useAuth();
  const [disabledField, setDisabledField] = useState('');
  const [aciklama, setAciklama] = useState('');
  let navigate = useNavigate();

  useEffect(() => {
    if (
      user &&
      user.authenticationProviderType === 'Customer' &&
      (!user.address || !user.city || user.city == 1000)
    ) {
      navigate('/profile');
      return;
    }
    if (
      user?.authenticationProviderType === 'Customer' ||
      user?.authenticationProviderType === 'Page'
    ) {
      setCustomerCode(user.userName);
      searchCustomer(null, user.userName);
      setDisabledField('');
    }
  }, [user]);

  useEffect(() => {
    GetSupplier('TRY').then((data) => {
      setFirmalar(data);
    });
    GetCurrency('TRY').then((data) => {
      setCurrency(data);
    });
  }, []);

  useEffect(() => {
    setTutar(parseFloat(price * miktar).toFixed(2));
    setTutarUsd(parseFloat((price * miktar) / currency).toFixed(2));
  }, [price, miktar]);

  useEffect(() => {
    setTotal(
      parseFloat(
        parseFloat(tutar / currency) + parseFloat(deliveryPrice)
      ).toFixed(2)
    );
  }, [tutar, currency, deliveryPrice]);

  const fields = {
    unvani: '',
    firma: '320.01.34.10001',
    country: 'TRY',
    dataUrl: '',
    productCode: '',
    color: '',
    image: '',
    variant: '',
    variantName: '',
    deliveryDate: '',
    marka: '',
    productName: ''
  };

  const [inputFields, setInputFields] = useState(fields);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setInputFields({ ...inputFields, [name]: value });
  };

  const setDefaults = (message) => {
    messageBox(message, 'error');
    setInputFields((prevValue) => {
      return { prevValue, unvani: prevValue.unvani, firma: prevValue.firma };
    });
    setProduct('');
  };

  const handleVariantChange = async (e) => {
    const { name, value } = e.target;
    if (platform == 'trendyol') {
      const newProduct = await GetPlatformData(
        platform,
        linkAdress,
        product.productCode,
        value
      );
      renderProductWithVariants(newProduct);
    }

    setInputFields({
      ...inputFields,
      [name]: value,
      variantName: product.allVariants.find((x) => x.itemNumber == value).value
    });
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    Object.values(inputFields).every((value) => {
      return String(value).length > 0;
    });

    const result = await SetOrderInsert({
      CariKodu: customerCode,
      Firma: platform,
      Country: country,
      StokKodu: inputFields.productCode.toString(),
      StokAdi: inputFields.productName.toString(),
      Marka: inputFields.marka,
      Beden: inputFields.variantName,
      Renk: inputFields.color,
      Miktar: miktar.toString(),
      BirimFiyat: price.toString(),
      Tutar: tutar,
      Kur: currency,
      Doviz: '0',
      NakliyeFiyati: deliveryPrice,
      Link: inputFields.dataUrl,
      Img: inputFields.image,
      Aciklama: aciklama,
      TerminTarihi: inputFields.deliveryDate
        ? inputFields.deliveryDate.toString()
        : '' // Boşsa kabul et
    });

    if (result) {
      setInputFields((prevValue) => {
        return { ...fields, unvani: prevValue.unvani, productName: '' };
      });
      setPrice(0);
      setTotal(0);
      setMiktar(1);
      setDeliveryPrice(0);
      setTutar(0);
      setTutarUsd(0);
      setAciklama('');
    }
    setIsSubmitting(false);
  };

  const renderProductWithVariants = (fetchedProduct) => {
    setProduct(fetchedProduct);
    setInputFields({
      ...inputFields,
      variant: fetchedProduct.variant,
      variantName: fetchedProduct.variantName
        ? fetchedProduct.variantName
        : 'Tek Ebat',
      image: fetchedProduct.img,
      color: fetchedProduct.color,
      marka: fetchedProduct.brand,
      deliveryDate: fetchedProduct.deliveryDate,
      productName: fetchedProduct.productName,
      productCode: fetchedProduct.productCode
    });
    var birimfiyat = fetchedProduct.price;
    if (birimfiyat === '') {
      birimfiyat = 0;
    }
    setPrice(birimfiyat.toString().replace(',', '.'));
  };

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const getProductCodeFromShortLink = async () => {
    const dataUrl = `https://api.mymobilestore.io/api/Siparis/GetLinkToResponsiveLocation?URI=${inputFields.dataUrl}`;
    const requestOptions = {
      method: 'GET'
    };
    const response = await fetch(dataUrl, requestOptions);
    const result = await response.text();
    let contentID = await TrenyolLinkToContentID(result);
    return contentID;

    // fetch(dataUrl, requestOptions)
    //   .then((response) => response.text())
    //   .then((result) => {
    //     let res = result;
    //     let contentID = TrenyolLinkToContentID(res);

    //     return contentID;
    //   });
  };

  const getProductCode = async () => {
    setLinkButtonDisebled(true);
    const webURL = inputFields.dataUrl ? inputFields.dataUrl + '?' : '';
    setLinkAdress(webURL);
    if (webURL) {
      // /(?:\/)([^#]+)(?=#*)/
      const outputPlatformName = await LinkToPlatformName(webURL);
      setPlatform(outputPlatformName);
      let outputProductCode = '';

      if (outputPlatformName === 'trendyol') {
        outputProductCode = TrenyolLinkToItemCode(webURL);

        if (!outputProductCode) {
          outputProductCode = await getProductCodeFromShortLink();
        }
        // webURL.match(/(?<=-p-)(.*?)(?=\?)+/) &&
      } else {
        outputProductCode = 'Urun1';
      }
      // webURL.match(/(?<=-p-)(.*?)(?=\?)+/)[0];
      // webURL.match(/(?<=www.)(.*?)(?=\.com)+/) &&
      // webURL.match(/(?<=www.)(.*?)(?=\.com)+/)[0];
      if (outputProductCode && outputPlatformName) {
        handleFormChange({
          target: { name: 'productCode', value: outputProductCode }
        });
        setInputFields({ ...inputFields, id: outputProductCode });
        if (outputProductCode) {
          try {
            const product = await GetPlatformData(
              outputPlatformName,
              webURL,
              outputProductCode,
              ''
            );
            renderProductWithVariants(product);
          } catch (err) {
            messageBox(err, 'error');
          }
        }
      } else {
        setDefaults('Ürün Bulunamadı....');
      }
    } else {
      setDefaults('Link Adres bulunamadı');
    }
    setLinkButtonDisebled(false);
  };

  const searchCustomer = async (e = null, userName = null) => {
    if (e) {
      e.preventDefault();
    }
    if (customerCode || userName) {
      const value = customerCode ? customerCode : userName;
      const customer = await GetCustomerByName(value);
      if (customer) {
        handleFormChange({
          target: { name: 'unvani', value: customer.unvani }
        });
      } else {
        setCostumerNotFound(true);
      }
    } else {
      setCostumerNotFound(true);
    }
  };

  const {
    marka,
    image,
    variant,
    variantName,
    color,
    dataUrl,
    unvani,
    deliveryDate,
    productName
  } = inputFields;

  return (
    <Layout>
      <div className="container my-4">
        <div className="order-wrapper">
          <div className="input-group floating-label-group">
            <select
              className="form-select form-select-lg"
              onChange={async (event) => {
                const selectedValue = event.target.value;
                const currency = await GetCurrency(selectedValue);
                setCountry(selectedValue);
                setCurrency(currency.toString().replace(',', '.'));
                const firma = await GetSupplier(selectedValue);
                setFirmalar(firma);
                handleFormChange(event);
                setTutarUsd(tutar / currency);
              }}
              name="Country"
              id="Country"
              required>
              <option value="TRY">TURKIYE</option>
              <option value="AED">UAE</option>
            </select>
            <label className="floating-label">{t('Ulke')}</label>
          </div>
          {!(
            user?.authenticationProviderType == 'Customer' ||
            user?.authenticationProviderType == 'Page'
          ) && (
            <div className="input-group floating-label-group position-relative costumer-z-5">
              <input
                type="text"
                id="customerCode"
                value={customerCode}
                className="form-control py-2"
                required
                onChange={(e) => setCustomerCode(e.target.value)}
              />
              <label className="floating-label">{t('Kodu')}</label>
              <button
                className="btn btn-outline-primary"
                type="button"
                id="button-addon1"
                onClick={searchCustomer}>
                {t('Arama')}
              </button>
              <CustomerCreatePage
                isShowCreatePage={costumerNotFound}
                setIsShowCreatePage={setCostumerNotFound}
                customerCode={customerCode}
                setCustomerCode={setCustomerCode}
                costumerNotFound={costumerNotFound}
                setParentFields={handleFormChange}
              />
            </div>
          )}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsShowConfirmDialog(true);
            }}>
            {!(
              user?.authenticationProviderType == 'Customer' ||
              user?.authenticationProviderType == 'Page'
            ) && (
              <>
                <div className="input-group floating-label-group">
                  <input
                    type="text"
                    name="unvani"
                    className="form-control py-2"
                    id="unvani"
                    value={unvani}
                    required
                    onChange={(event) => handleFormChange(event)}
                  />
                  <label className="floating-label">{t('Adi')}</label>
                </div>

                <div className="input-group floating-label-group">
                  <select
                    className="form-select form-select-lg"
                    onChange={(event) => handleFormChange(event)}
                    name="firma"
                    id="firma"
                    required
                    defaultValue="320.01.34.10001">
                    {/* <option value="320.01.34.10001">Trendyol</option> */}
                    {(firmalar?.length &&
                      firmalar.map((firma) => {
                        return (
                          <option value={firma.code} key={firma.code}>
                            {firma.name}
                          </option>
                        );
                      })) ||
                      ''}
                  </select>
                  <label className="floating-label">{t('Ulke')}</label>
                </div>
              </>
            )}

            <div className="input-group floating-label-group">
              <input
                type="text"
                name="dataUrl"
                className="form-control py-2"
                id="dataUrl"
                value={dataUrl}
                required
                onChange={(event) => handleFormChange(event)}
              />
              <label className="floating-label">{t('Link')}</label>
              <button
                className="btn btn-outline-primary"
                type="button"
                id="button-addon1"
                disabled={linkButtonDisebled}
                onClick={getProductCode}>
                {t('Tamam')}
              </button>
            </div>
            <div className="input-group floating-label-group">
              <select
                className="form-select form-select-lg"
                onChange={handleVariantChange}
                name="variant"
                value={variant}>
                <option>{t('BedenSecin')}</option>
                {product ? (
                  product.allVariants.length ? (
                    product.allVariants.reduce((filtered, variant) => {
                      if (variant.inStock === 'true') {
                        filtered.push(
                          <option
                            value={variant.itemNumber}
                            key={variant.itemNumber}>
                            {variant.value ? variant.value : t('TekBeden')}
                          </option>
                        );
                      }
                      return filtered;
                    }, [])
                  ) : (
                    <option value="">Tek Ebat</option>
                  )
                ) : (
                  ''
                )}
              </select>
            </div>
            <div className="input-group floating-label-group">
              <input
                type="text"
                name="variantName"
                className="form-control py-2"
                id="variantName"
                value={variantName}
                required
                onChange={(event) => handleFormChange(event)}
                onInput={toInputUppercase}
              />
              <label className="floating-label">{t('Beden')}</label>
            </div>
            <div className="input-group floating-label-group">
              <input
                type="text"
                name="color"
                className="form-control py-2"
                id="color"
                value={color}
                required
                onChange={(event) => handleFormChange(event)}
                onInput={toInputUppercase}
              />
              <label className="floating-label">{t('Renk')}</label>
            </div>
            <div className="input-group floating-label-group">
              <input
                type="text"
                name="productName"
                className="form-control py-2"
                id="productName"
                value={productName}
                onChange={(event) => handleFormChange(event)}
                onInput={toInputUppercase}
                required
              />
              <label className="floating-label">{t('UrunAdi')}</label>
            </div>
            <div className="input-group floating-label-group">
              <input
                type="text"
                name="marka"
                className="form-control py-2"
                id="marka"
                value={marka}
                required
                onChange={(event) => handleFormChange(event)}
                onInput={toInputUppercase}
              />
              <label className="floating-label">{t('Marka')}</label>
            </div>

            <div className="row">
              <div className="input-group floating-label-group col">
                <input
                  type="number"
                  name="miktar"
                  className="form-control py-2"
                  id="miktar"
                  value={miktar}
                  required
                  onChange={(event) => setMiktar(event.target.value)}
                />
                <label className="floating-label">{t('Miktar')}</label>
              </div>
              <div className="input-group floating-label-group col">
                <input
                  type="number"
                  name="price"
                  className="form-control py-2"
                  id="price"
                  value={price}
                  required
                  disabled={disabledField}
                  onChange={(event) => {
                    setPrice(event.target.value);
                  }}
                />
                <label className="floating-label">{t('Fiyat')}</label>
              </div>
              <div className="input-group floating-label-group col">
                <input
                  type="number"
                  name="tutar"
                  className="form-control py-2"
                  id="tutar"
                  value={tutar}
                  required
                  disabled
                />
                <label className="floating-label floating-label-focused">
                  {t('Tutar')}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input-group floating-label-group col">
                <input
                  type="number"
                  name="deliveryPrice"
                  className="form-control py-2"
                  id="deliveryPrice"
                  value={deliveryPrice}
                  required
                  disabled={disabledField}
                  onBlur={(event) =>
                    setDeliveryPrice(
                      event.target.value ? event.target.value : 0
                    )
                  }
                  onFocus={(event) =>
                    setDeliveryPrice(
                      event.target.value != 0 ? event.target.value : ''
                    )
                  }
                  onChange={(event) => setDeliveryPrice(event.target.value)}
                />
                <label className="floating-label floating-label-focused">
                  {t('KargoUcreti')}
                </label>
              </div>
              <div className="input-group floating-label-group col">
                <input
                  type="number"
                  name="currency"
                  className="form-control py-2"
                  id="currency"
                  value={currency}
                  required
                  disabled
                />
                <label className="floating-label">{t('DovizKuru')}</label>
              </div>
              <div className="input-group floating-label-group col">
                <input
                  type="number"
                  name="tutarUsd"
                  className="form-control py-2"
                  id="total"
                  value={tutarUsd}
                  onChange={(event) => {
                    setTutarUsd(event.target.value);
                    setTutar(event.target.value * currency);
                  }}
                  required
                />
                <label className="floating-label">{t('TutarUsd')}</label>
              </div>
            </div>
            <div className="input-group floating-label-group col">
              <input
                type="number"
                name="total"
                className="form-control py-2"
                id="total"
                value={total}
                required
                disabled
              />
              <label className="floating-label">{t('Toplam')}</label>
            </div>
            <div className="input-group floating-label-group">
              <input
                type="text"
                name="deliveryDate"
                className="form-control py-2"
                id="deliveryDate"
                value={deliveryDate}
                onChange={(event) => handleFormChange(event)}
                onInput={toInputUppercase}
              />
              <label className="floating-label">{t('TeslimTarihi')}</label>
            </div>
            <div className="input-group floating-label-group">
              <input
                type="text"
                name="aciklama"
                className="form-control py-2"
                id="aciklama"
                value={aciklama}
                onChange={(event) => setAciklama(event.target.value)}
                onInput={toInputUppercase}
              />
              <label className="floating-label">{t('Note')}</label>
            </div>
            <div className="input-group floating-label-group">
              <input
                type="text"
                name="image"
                className="form-control py-2"
                id="image"
                value={image}
                required
                onChange={(event) => handleFormChange(event)}
              />
              <label className="floating-label">{t('Resim')}</label>
            </div>
            <UploadPhoto image={image} handleFormChange={handleFormChange} />
            {image && (
              <div className="d-flex justify-content-center w-100">
                <img src={image} width={200} height={300}></img>
              </div>
            )}
            <button
              type="submit"
              className="btn btn-primary btn-block position-relative btn-lg"
              disabled={isSubmitting}>
              {t('Kaydet')}
              {isSubmitting && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="position-absolute top-50 start-50 translate-middle"
                />
              )}
            </button>
          </form>
        </div>
      </div>
      <DialogConfirm
        isShowConfirmDialog={isShowConfirmDialog}
        setIsShowConfirmDialog={setIsShowConfirmDialog}
        setDialogResult={() => {
          setIsShowConfirmDialog(false);
          handleSubmit();
        }}
      />
    </Layout>
  );
}
export default SiparisGiris;
