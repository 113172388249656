import { createContext, useEffect, useReducer, useState } from 'react';
import { verify, JWT_SECRET } from '../utils/jwt';
import PropTypes from 'prop-types';
import useMessage from '../features/hooks/useMessage';
import internationalization from '../assets/i18n/i18n';
import apiClient from '@/utils/axios';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  success: '',
  message: ''
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    apiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete apiClient.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const { messageBox } = useMessage();
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [notifications, setNotifications] = useState([]);

  const getUnreadNotifications = async () => {
    if (state.user) {
      const response = await apiClient.get('/api/Cargo/GetNotificationList');
      if (response.data.success) {
        setNotifications(response.data.data);
      }
    }
  };

  useEffect(() => {
    if (state.user) {
      internationalization.changeLanguage(state.user.language);
      setInterval(() => {
        getUnreadNotifications();
      }, 300000);
      getUnreadNotifications();
    }
  }, [state.user]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && verify(accessToken, JWT_SECRET)) {
          setSession(accessToken);
          try {
            const response = await apiClient.get('/api/App/GetUserByName');
            const user = response.data.data[0];
            internationalization.changeLanguage(user.language);
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user
              }
            });
          } catch (error) {
            logout();
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (UserName, Password) => {
    try {
      const response = await apiClient.post('/api/Auth/login', {
        UserName: UserName,
        Password: Password
      });

      const { token } = response.data.data;

      setSession(token);

      const responseUser = await apiClient.get('/api/App/GetUserByName');

      const user = responseUser.data.data[0];

      internationalization.changeLanguage(user.language);

      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    } catch (err) {
      messageBox(err, 'error');
      // return ["error", err.response.data];
      // throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
    }
  };

  const GetUserTemporaryPassword = async (userName, password) => {
    try {
      const response = await apiClient.get(
        `/api/App/GetUserTemporaryPassword?userName=${userName}&password=${password}`
      );

      if (response.data.data[0].sonuc) {
        messageBox('Success', 'success');
        await login(userName, password);
      } else {
        messageBox('Password Error', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const setUserPassword = async (oldPassword, newPassword) => {
    try {
      await apiClient.get(
        `/api/App/SetUserPasswordChange?oldPassword=${oldPassword}&newPassword=${newPassword}`
      );
      messageBox('Success', 'success');

      const responseUser = await apiClient.get('/api/App/GetUserByName');

      const user = responseUser.data.data[0];

      internationalization.changeLanguage(user.language);

      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const SetUserForgetPassword = async (userName, phone) => {
    try {
      const response = await apiClient.get(
        `/api/App/SetUserForgetPassword?userName=${userName}&phone=${phone}`
      );
      return response;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (userInfo) => {
    try {
      const response = await apiClient.post('/api/Auth/register', {
        Email: userInfo.Email,
        UserName: userInfo.UserName,
        Password: userInfo.Password,
        FullName: userInfo.FullName,
        MobilePhones: userInfo.MobilePhones
      });

      if (response.data.success) {
        await login(userInfo.UserName, userInfo.Password);
      } else {
        messageBox('Password Error', 'error');
      }
    } catch (error) {
      messageBox(error?.message ? error.message : 'Hata oluştu', 'error');
    }

    // dispatch({
    //     type: 'REGISTER',
    //     payload: {
    //         success,
    //         message
    //     }
    // });
  };

  const SetUserLanguage = async (Lang) => {
    try {
      const response = await apiClient.get(
        '/api/App/SetUserLanguage?Lang=' + Lang
      );
      const data = response.data.data[0];

      dispatch({
        type: 'REGISTER',
        payload: {
          type: 'LOGIN',
          user: data
        }
      });
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        setNotifications,
        SetUserLanguage,
        setUserPassword,
        SetUserForgetPassword,
        GetUserTemporaryPassword,
        notifications
      }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
