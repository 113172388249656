const arJSON = {
  Tamam: 'حسناً',
  Tarih: 'تاريخ',
  Bugun: 'اليوم',
  BuHafta: 'هذا الأسبوع',
  BuAy: 'هذا الشهر',
  BuYil: 'هذا العام',
  SifreEslesmiyor: 'كلمات المرور غير متطابقة',
  SifreDegistir: 'تغيير كلمة المرور',
  MevcutSifre: 'كلمة المرور الحالية',
  YeniSifre: 'كلمة المرور الجديدة',
  SifreyiDogrula: 'تأكيد كلمة المرور',
  Kaydet: 'حفظ',
  Geri: 'رجوع',
  SifreTelefonunuzaGonderildi: 'تم إرسال كلمة المرور إلى هاتفك',
  SifrenizDahaOnceGonderildi: 'تم إرسال كلمة المرور من قبل',
  SifremiUnuttum: 'نسيت كلمة المرور',
  KullaniciAdi: 'اسم المستخدم',
  TelefonVeyaEmail: 'الهاتف أو البريد الإلكتروني',
  Sifirla: 'إعادة تعيين',
  SifremVar: 'لدي كلمة مرور',
  Giris: 'تسجيل الدخول',
  GeciciSifre: 'كلمة مرور مؤقتة',
  Sifre: 'كلمة المرور',
  KayitOl: 'التسجيل',
  GecersizTelefon: 'رقم الهاتف غير صالح',
  GecersizSifre: 'كلمة مرور غير صالحة',
  AdiSoyadi: 'الاسم الكامل',
  Email: 'البريد الإلكتروني',
  Gsm: 'الهاتف المحمول',
  UyeOl: 'الاشتراك',
  DahaOnceKayitli: 'مسجل بالفعل',
  Telefon1: 'الهاتف 1',
  Telefon2: 'الهاتف 2',
  Adres: 'العنوان',
  Sehir: 'المدينة',
  Ulke: 'البلد',
  Instagram: 'إنستجرام',
  Facebook: 'فيسبوك',
  Baslik: 'العنوان',
  Sira: 'الترتيب',
  EvrakTipi: 'نوع المستند',
  Cinsi: 'النوع',
  Tipi: 'النوع',
  AracNo: 'رقم المركبة',
  Note: 'ملاحظة',
  Aciklama: 'الوصف',
  Aciklama1: 'الوصف 1',
  Aciklama2: 'الوصف 2',
  Borc: 'الدائن',
  Alacak: 'المدين',
  Bakiye: 'الرصيد',
  Depo: 'المستودع',
  TeslimNoktasi: 'نقطة التسليم',
  SiparisNo: 'رقم الطلب',
  KayitNo: 'رقم التسجيل',
  Bekleyen: 'معلق',
  Detay: 'التفاصيل',
  Yol: 'الطريق',
  Saha: 'الحقل',
  Kapi: 'الباب',
  Biten: 'منتهي',
  TarihTutar: 'تاريخ القيمة',
  AraToplam: 'المجموع الفرعي',
  GonderilenHavale: 'التحويل المرسل',
  KapSayisi: 'عدد الأبواب',
  Kg: 'كجم',
  M3: 'م³',
  M3Kg: 'م³/كجم',
  CariHesapLimiti: 'حد الحساب الحالي',
  Kodu: 'الكود',
  Arama: 'البحث',
  Adi: 'الاسم',
  Soyadi: 'اللقب',
  Vade: 'تاريخ الاستحقاق',
  Toplam: 'المجموع',
  Doviz: 'العملة',
  AnaSayfa: 'الصفحة الرئيسية',
  Menu: 'القائمة',
  Platforms: 'المنصات',
  Kampanya: 'الحملة',
  Takipci: 'المتابع',
  Sube: 'الفرع',
  Tr: 'اللغة التركية',
  En: 'اللغة الإنجليزية',
  De: 'الألمانية',
  Ar: 'العربية',
  Kr: 'الكردية',
  OkunmamisMesajlar: 'الرسائل غير المقروءة',
  Bildirimler: 'الإشعارات',
  TumunuGoruntule: 'عرض الكل',
  Hakkinda: 'حول',
  FaturaBilgileri: 'معلومات الفاتورة',
  iletisim: 'اتصل بنا',
  OneriSikayet: 'الاقتراحات والشكاوى',
  Degistir: 'تغيير',
  Telefon: 'رقم الهاتف',
  TelefonNumarasi: 'رقم الهاتف',
  TumSubeler: 'كافة الفروع',
  Unvani: 'العنوان',
  LutfenSehirSeciniz: 'يرجى اختيار مدينة',
  MusteriSayfasi: 'صفحة العميل',
  Link: 'الرابط',
  BedenSecin: 'اختر المقاس',
  TekBeden: 'مقاس واحد',
  Variant: 'الاختلاف',
  Beden: 'المقاس',
  Renk: 'اللون',
  UrunAdi: 'اسم المنتج',
  Miktar: 'الكمية',
  Fiyat: 'السعر',
  Tutar: 'المبلغ',
  TutarUsd: 'المبلغ بالدولار',
  KargoUcreti: 'رسوم الشحن',
  DovizKuru: 'سعر الصرف',
  TeslimTarihi: 'تاريخ التسليم',
  Resim: 'الصورة',
  Durum: 'الحالة',
  Kargo: 'الشحن',
  Firma: 'الشركة',
  Marka: 'العلامة التجارية',
  GumrukUcreti: 'رسوم الجمارك',
  Duzenle: 'تعديل',
  Sil: 'حذف',
  Onay: 'تأكيد',
  Iptal: 'إلغاء',
  SubeListesi: 'قائمة الفروع',
  SiparisListesi: 'قائمة الطلبات',
  YeniSiparis: 'طلب جديد',
  SiparisOnay: 'تأكيد الطلب',
  MusteriHesapListesi: 'قائمة حسابات العملاء',
  MusteriListesi: 'قائمة العملاء',
  TeslimEdildi: 'تم التسليم',
  MusteriHesabi: 'حساب العميل',
  Devir: 'التحويل',
  _Devir_: 'التحويل',
  AlisFaturasi: 'فاتورة الشراء',
  SatisFaturasi: 'فاتورة البيع',
  Fatura: 'الفاتورة',
  Tahsilat: 'التحصيل',
  Odeme: 'الدفع',
  Masraf: 'المصاريف',
  Cari: 'العميل',
  CariPersonel: 'العميل/الموظف',
  Kasa: 'الخزينة',
  Banka: 'البنك',
  Hizmet: 'الخدمة',
  MuhasebeHesabı: 'حساب المحاسبة',
  Personel: 'الموظف',
  Demirbas: 'الأصول الثابتة',
  İthalat: 'الاستيراد',
  Leasing: 'التأجير',
  Kredi: 'القرض',
  Stok: 'المخزون',
  TumSiparisler: 'كافة الطلبات',
  Beklemede: 'معلق',
  KontrolEdiliyor: 'قيد الفحص',
  MusteriOnayinda: 'تأكيد العميل',
  MusteriIptali: 'إلغاء العميل',
  Onaylandi: 'تم التأكيد',
  Tedarik: 'التوريد',
  Hazirlaniyor: 'جارٍ التجهيز',
  TurkiyeDepo: 'مستودع تركيا',
  YurtDisiKargo: 'شحن إلى الخارج',
  Red: 'رفض',
  Iade: 'إرجاع',
  HasarliUrun: 'منتج تالف',
  Subede: 'في الفرع',
  Document1: 'جواز السفر',
  Document2: 'بطاقة الهوية',
  Dikkat: 'تحذير',
  Onayliyormusunuz: 'هل أنت متأكد؟',
  Evet: 'نعم',
  Hayir: 'لا',
  Tahakkuk: 'التراكم'
};

export default arJSON;
