import { useEffect, useState } from 'react';
import './Login.css';
import resim from '../../../assets/images/login.jpeg';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../../features/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import useLinkParams from '../../../features/hooks/useLinkParams';
import useNotificationApiContext from '../../../context/api/useNotificationApiContext';
import { renderIcon } from '@/utils/iconRenderer';

function Login() {
  const { SetDeviceToken } = useNotificationApiContext();
  const { deviceToken, apiVersion, platform } = useLinkParams();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [requestPending, setRequestPending] = useState(false);
  const { login, isAuthenticated } = useAuth();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const isSubmit = userName && password;

  const submitHandler = async (e) => {
    e.preventDefault();
    setRequestPending(true);
    await login(userName, password);
    setRequestPending(false);
    if (deviceToken) {
      SetDeviceToken(deviceToken, apiVersion, platform);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/`);
    }
  }, [isAuthenticated]);

  return (
    <div className="container px-4 px-lg-0 login-container">
      <div className="row d-grid d-flex bg-sm-image">
        <div className="col-lg-6 col-md-4 col-sm-10">
          <img src={resim} className="img-login" />
        </div>
        <div className="m-auto rounded-lg form-login g-0 col-lg-6 col-md-8 col-sm-10">
          <div className="card g-0" border="gray">
            <div className="card-header card-header-title text-color-success">
              <img
                src="/images/icons/logo.png"
                className="img-fluid"
                width={60}
                height={60}></img>
              <div>{t('Giris')}</div>
            </div>
            <div className="card-body">
              <form onSubmit={(e) => submitHandler(e)} className="d-grid">
                <div className=" mb-3">
                  <label htmlFor="username" className="form-label">
                    {t('TelefonNumarasi')}
                  </label>
                  <div className="input-group">
                    <div className="bg-color-layout-shadow d-flex align-items-center p-1 px-2">
                      <span className="black-color">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={22}
                          height={22}
                          viewBox="0 0 20 20"
                          fill="currentColor">
                          <path
                            fillRule="evenodd"
                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                    <input
                      className="formControl-text form-control"
                      id="username"
                      type="number"
                      placeholder={t('TelefonNumarasi')}
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    {t('Sifre')}
                  </label>
                  <div className="input-group">
                    <div className="bg-color-layout-shadow d-flex align-items-center p-1 px-2">
                      <span className="black-color">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={22}
                          height={22}
                          viewBox="0 0 20 20"
                          fill="currentColor">
                          <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                    <input
                      className="formControl-text form-control"
                      id="password"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <button
                  className="btn btn-full fw-bold fs-5 rounded ls-1 text-uppercase font-monospace text-color-black btn-primary"
                  onClick={(e) => submitHandler(e)}
                  type="submit"
                  style={{ border: '1px solid #000' }} // Adding a 1px solid border
                >
                  {renderIcon('circle-check', 24, 'text-color-white mx-2')}
                  {t('Giris')}{' '}
                </button>
              </form>
              <p className="forgot-password text-end">
                {' '}
                <Link to="/auth/register">{t('KayitOl')}</Link>{' '}
              </p>
              <p className="forgot-password text-start">
                {' '}
                <Link to="/auth/ForgetPassword">
                  {t('SifremiUnuttum')}
                </Link>{' '}
              </p>
            </div>
          </div>
          <div>Api : {apiVersion} </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
