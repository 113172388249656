import useMessage from '@/features/hooks/useMessage';
import apiClient from '@/utils/axios';

export default function useAppApiContext() {
  const { messageBox } = useMessage();

  const GetUserByName = async () => {
    try {
      const response = await apiClient.get('/api/App/GetUserByName');
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const SetUserPhotoAdress = async (photoinfo) => {
    try {
      const response = await apiClient.post('/api/App/SetUserPhotoAdress', {
        Adres: photoinfo.Adres
      });
      const data = response.data.data;
      messageBox(data.message, 'success');
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const SetUserProfileUpdate = async (userInfo) => {
    try {
      const response = await apiClient.post('/api/App/SetUserProfileUpdate', {
        FullName: userInfo.fullName,
        Email: userInfo.email,
        MobilePhones: userInfo.mobilePhones,
        Address: userInfo.address,
        City: userInfo.city,
        Country: userInfo.country,
        Location1: userInfo.location1,
        Location2: userInfo.location2,
        SocialMedia1: userInfo.SocialMedia1,
        SocialMedia2: userInfo.SocialMedia2
      });
      const data = response.data;
      messageBox(data.message, 'success');
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const DeleteUser = async () => {
    try {
      const response = await apiClient.get('/api/App/DeleteUser');
      const data = response.data;
      messageBox(data.message, 'success');
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetCustomerList = async (data) => {
    try {
      const response = await apiClient.post(`/api/App/GetCustomerList`, data);
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
      // const data = response.data.data[0];
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetSubeList = async () => {
    try {
      const response = await apiClient.get(`/api/App/GetSubeList`);
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetCustomerActionList = async (ilkTarih, sonTarih) => {
    try {
      const response = await apiClient.get(
        '/api/App/GetCustomerActionList?ilkTarih=' +
          ilkTarih +
          '&SonTarih=' +
          sonTarih
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetBranchList = async () => {
    try {
      const response = await apiClient.get('/api/App/GetBranchList');
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  return {
    GetUserByName,
    SetUserPhotoAdress,
    SetUserProfileUpdate,
    GetCustomerList,
    GetSubeList,
    DeleteUser,
    GetCustomerActionList,
    GetBranchList
  };
}
