
import { NavLink } from 'react-router-dom';
import useAuth from '../../../../features/hooks/useAuth';
import useMenu from '../../../../features/hooks/useMenu';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './ChangeLanguage';

export default function ProfileMenu({ scaleClass, setIsShowProfile }) {
    const { logout } = useAuth();
    const rand = Math.floor(Math.random() * 1000) + 1 // for disable caching profile photo
    const { user } = useAuth();
    const { selectedComponentHandler } = useMenu();
    const { t } = useTranslation();
    let showLayout = "";
    scaleClass ? showLayout = "show-layout" : showLayout = "";
    return (
        <>
            <div className={`gray-layout ${showLayout}`} onClick={() => setIsShowProfile()}></div>
            <div id="list-example" className={`shadow rounded position-absolute bg-white nav-user-container pb-2 list-group ${scaleClass}`}>
                <div className='rounded p-3 gl-page-header-container-secondary align-items-center mb-2'>
                    <img src={user?.image ? user.image + "&version=" + rand : "/images/avatar/UserNull.png"} width={32} height={32} className="rounded-circle"></img>
                    <p className='text-center fs-6 my-0 ms-2'>{user?.fullName}</p>
                </div>

                <NavLink to={"/profile"} className='px-4 py-2 text-center d-flex align-items-center justify-content-between text-hover black-color mx-2 rounded-pill' onClick={() => { selectedComponentHandler(""); setIsShowProfile(false) }}>
                    <div className='d-flex align-items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user me-50"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        {t("Profil")}
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                    </svg>
                </NavLink>

                <ChangeLanguage />

                <hr className="bg-secondary border-1 border-top" />
                <a className='btn text-color-decorative-secondary d-flex align-items-center gap-2 justify-content-center btn-logout mx-2' onClick={() => logout()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-power me-50"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg>
                    <span> {t("Cikis")}</span>
                </a>
            </div>
        </>
    )
}
