import { createContext } from 'react';
import { useState, useEffect } from 'react';
import useRoles from '../features/helpers/isAdmin';
import useOrderContext from './api/useOrderContext';
export const OrderContext = createContext({});
export const OrderProvider = ({ children }) => {
  const { isCustomer, isPlatformAdmin, isPage, isUnknown } = useRoles();
  const [orderWaitingList, setOrderWaitingList] = useState('');
  const { GetOrderApprovaList } = useOrderContext();

  const getOrderApprovaList = async () => {
    const result = await GetOrderApprovaList();
    setOrderWaitingList(result);
  };

  useEffect(() => {
    if (isCustomer || isPlatformAdmin || isPage || isUnknown) {
      getOrderApprovaList();
    }
  }, [isCustomer, isPlatformAdmin, isPage, isUnknown]);

  return (
    <OrderContext.Provider
      value={{
        orderWaitingList,
        getOrderApprovaList
      }}>
      {children}
    </OrderContext.Provider>
  );
};
