import { Route, Routes } from 'react-router-dom';
import ForgetPassword from '../content/Auth/ForgetPassword';
import Login from '../content/Auth/Login/Login';
import Register from '../content/Auth/Register';
import ChangePassword from '../content/Auth/ChangePassword';
import RequireAuth from './RequireAuth';

const Auth = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="login" element={<Login />} />
        <Route
          path="changePassword"
          element={
            <RequireAuth>
              <ChangePassword />
            </RequireAuth>
          }
        />
        <Route path="Register" element={<Register />} />
        <Route path="ForgetPassword" element={<ForgetPassword />} />
      </Route>
    </Routes>
  );
};

export default Auth;
