import  {lazy, Suspense, useEffect, useState } from 'react';
import Header from './Header'
import BottomMenu from './BottomMenu';
import useMenu from '../../features/hooks/useMenu';
import useAuth from '../../features/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import WaitingSpinner from '../../components/WaitingSpinner';
const Sidebar = lazy(() => import('./Sidebar'));

export default function Layout({ children }) {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const { CurrentComponent } = useMenu();
    const [showSideber, setShowSidebar] = useState(false);
    const [isToggleOpen, setIsToggleOpen] = useState("hidden"); // enum: hidden or visible
    const isShowBottomMenu = import.meta.env.VITE_APP_BOTTOM_MENU;
    const { isAuthenticated, isInitialized, user } = useAuth();

    useEffect(() => {
        if (!isAuthenticated && isInitialized) {
            navigate(`/auth/login`);
        }
        else if (user?.changePassword) {
            navigate("/auth/changePassword");
        }
    }, [isAuthenticated, isInitialized]);

    let mainToggleClass = "";
    isToggleOpen === "hidden" ? mainToggleClass = "" : mainToggleClass = "main-toggle-expanded";

    const showSidebarHandler = (value) => {
        setShowSidebar(value);
    }

    const renderProp = CurrentComponent ? <CurrentComponent>{children}</CurrentComponent> : children;

    if (isAuthenticated) {
        return (
            <>
                <Header showSidebarHandler={showSidebarHandler} mainToggleClass={mainToggleClass} />
                <main className={`main-height ${mainToggleClass}`}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Sidebar sidebarData={{ showSideber, showSidebarHandler, isToggleOpen, setIsToggleOpen }}></Sidebar>
                    </Suspense>
                    <div className='main-container'>
                        {renderProp}
                    </div>
                </main>
                {isShowBottomMenu === "true" && <BottomMenu />}
            </>
        );
    } else {
        return (
            <WaitingSpinner />
        )
    }
}

