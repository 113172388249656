import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDate from '../../features/hooks/useDate';
import './DateSeletion.css';

function DateSelection({ isShowDate, handleDateClick }) {
  const { t } = useTranslation();

  const { ilkTarih, sonTarih, setilkTarih, setSonTarih } = useDate();
  const [inputIlkTarih, setInputIlkTarih] = useState(ilkTarih);
  const [inputSonTarih, setInputSonTarih] = useState(sonTarih);

  const [inputDateValue, setInputDateValue] = useState('1yil');

  let showDateClass = '';
  isShowDate ? (showDateClass = 'show-date') : (showDateClass = '');

  function getLastWeeksDate(removingDate = 0) {
    const now = new Date();
    const getCurrentDate = new Date(
      now.getTime() - removingDate * 24 * 60 * 60 * 1000
    );
    if (removingDate == '30') {
      setInputIlkTarih(
        `${now.getFullYear()}-${('0' + (now.getMonth() + 1)).slice(-2)}-01`
      );
    } else if (removingDate == '7') {
      setInputIlkTarih(
        `${getCurrentDate.getFullYear()}-${(
          '0' +
          (getCurrentDate.getMonth() + 1)
        ).slice(-2)}-${('0' + getCurrentDate.getDate()).slice(-2)}`
      );
    } else if (removingDate == '365') {
      setInputIlkTarih(`${now.getFullYear()}-01-01`);
    } else {
      setInputIlkTarih(
        `${getCurrentDate.getFullYear()}-${(
          '0' +
          (getCurrentDate.getMonth() + 1)
        ).slice(-2)}-${('0' + getCurrentDate.getDate()).slice(-2)}`
      );
    }
    setInputSonTarih(
      `${now.getFullYear()}-${('0' + (now.getMonth() + 1)).slice(-2)}-${(
        '0' + now.getDate()
      ).slice(-2)}`
    );
  }

  function handleRadioChange(e) {
    setInputDateValue(e.target.value);
    switch (e.target.value) {
      case 'bugun':
        getLastWeeksDate();
        break;
      case 'buhafta':
        getLastWeeksDate(7);
        break;
      case '1ay':
        getLastWeeksDate(30);
        break;
      default:
        getLastWeeksDate(365);
        break;
    }
    // handleClick();
  }

  function handleClick() {
    setilkTarih(inputIlkTarih);
    setSonTarih(inputSonTarih);
    handleDateClick();
  }

  return (
    <>
      <div
        className={`gray-layout ${showDateClass}`}
        onClick={() => handleDateClick()}></div>
      <div className={`DateSelection ${showDateClass}`}>
        <div className="py-2 px-2 bg-color-primary bg-gradient rounded m-2 gl-page-header-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            width={50}
            height={50}
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <p className="text-center m-0 fs-5">{t('Tarih')}</p>
        </div>
        <table className="table table-stripe">
          <tbody>
            <tr className="label-active">
              <td width="10%">
                <input
                  className="form-check-input"
                  type="radio"
                  id="bugun"
                  value="bugun"
                  checked={inputDateValue == 'bugun'}
                  onChange={(e) => handleRadioChange(e)}
                />
              </td>
              <td>
                <label htmlFor="bugun">{t('Bugun')}</label>
              </td>
            </tr>
            <tr>
              <td width="10%">
                <input
                  className="form-check-input"
                  type="radio"
                  id="buhafta"
                  value="buhafta"
                  checked={inputDateValue == 'buhafta'}
                  onChange={(e) => handleRadioChange(e)}
                />
              </td>
              <td>
                <label htmlFor="buhafta">{t('BuHafta')}</label>
              </td>
            </tr>
            <tr>
              <td width="10%">
                <input
                  className="form-check-input"
                  type="radio"
                  id="1ay"
                  value="1ay"
                  checked={inputDateValue == '1ay'}
                  onChange={(e) => handleRadioChange(e)}
                />
              </td>
              <td>
                <label htmlFor="1ay">{t('BuAy')}</label>
              </td>
            </tr>
            <tr>
              <td width="10%">
                <input
                  className="form-check-input"
                  type="radio"
                  id="tarih"
                  value="1yil"
                  checked={inputDateValue == '1yil'}
                  onChange={(e) => handleRadioChange(e)}
                />
              </td>
              <td>
                <label htmlFor="tarih">{t('BuYil')}</label>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <input
                  type="date"
                  className="me-2 date-picker"
                  value={inputIlkTarih}
                  onChange={(e) => {
                    setInputIlkTarih(e.target.value);
                    setInputDateValue('');
                  }}></input>
                <input
                  type="date"
                  className="date-picker"
                  value={inputSonTarih}
                  onChange={(e) => {
                    setInputSonTarih(e.target.value);
                    setInputDateValue('');
                  }}></input>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                {/* <button type="button" className="btn btn-primary w-100">SEÇ</button> */}
                <button
                  className="btn btn-outline-primary btn-md btn text-center d-flex align-items-center justify-content-center w-100"
                  onClick={() => handleClick()}>
                  {t('Tamam')}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-1 "
                    width={18}
                    height={18}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
DateSelection.propTypes = {
  isShowDate: PropTypes.bool.isRequired,
  handleDateClick: PropTypes.func.isRequired
};

export default DateSelection;
