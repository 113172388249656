import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../../features/hooks/useAuth';
import useMessage from '../../../features/hooks/useMessage';

function ForgetPassword() {
  const [userName, setUserName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const { messageBox } = useMessage();
  const [requestPending, setRequestPending] = useState(false);
  const [tempPassExisted, setTempPassExisted] = useState(false);
  const { SetUserForgetPassword, GetUserTemporaryPassword, isAuthenticated } =
    useAuth();
  let navigate = useNavigate();
  const isSubmit = userName && userName;

  const sendTempPass = async (e) => {
    setRequestPending(true);
    e.preventDefault();
    const response = await SetUserForgetPassword(userName, phone);
    if (response.data.data[0].sonuc == 1) {
      messageBox(t('SifreTelefonunuzaGonderildi'), 'success');
    } else if (response.data.data[0].sonuc == 0) {
      messageBox(t('SifrenizDahaOnceGonderildi'), 'error');
    }
    setTempPassExisted(true);
    setRequestPending(false);
  };

  const login = async (e) => {
    e.preventDefault();
    await GetUserTemporaryPassword(userName, password);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/auth/changePassword`);
    }
  }, [isAuthenticated]);

  return (
    <div className="container px-4 px-lg-0">
      <div className="row d-grid d-flex bg-sm-image">
        <div className="mt-4 m-auto rounded-lg form-login g-0 col-lg-6 col-md-8 col-sm-10">
          {!tempPassExisted ? (
            <div className="card g-0" border="gray">
              <div className="card-header card-header-title text-color-success">
                <img
                  src="/images/icons/logo.png"
                  className="img-fluid"
                  width={60}
                  height={60}></img>
                <div> {t('SifremiUnuttum')}</div>
              </div>
              {/* <div className="card-header card-header-title text-color-success">
                                Forgot Password
                                </div> */}
              <div className="card-body">
                <form onSubmit={(e) => sendTempPass(e)} className="d-grid">
                  <div className=" mb-3">
                    <label htmlFor="username" className="form-label">
                      {' '}
                      {t('KullaniciAdi')}
                    </label>
                    <input
                      className="formControl-text form-control"
                      id="username"
                      type="text"
                      placeholder="User name"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      {' '}
                      {t('TelefonVeyaEmail')}
                    </label>
                    <input
                      className="formControl-text form-control"
                      id="phone"
                      type="text"
                      placeholder="Phone Number"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </div>

                  <button
                    className="btn btn-full text-white fw-bold fs-5 rounded ls-1 text-uppercase font-monospace bg-color-success"
                    onClick={(e) => sendTempPass(e)}
                    type="submit"
                    disabled={!isSubmit || requestPending}>
                    {t('Sifirla')}{' '}
                    {requestPending && (
                      <img
                        src="/images/avatar/loading.gif"
                        alt="avatar"
                        className="rounded-circle img-fluid"
                        style={{ width: '25px' }}
                      />
                    )}
                  </button>
                </form>
                <p
                  className="forgot-password text-left btn btn-light btn-sm mt-2"
                  onClick={() => setTempPassExisted(true)}>
                  {t('SifremVar')}
                </p>
                <p className="forgot-password text-right">
                  {' '}
                  <Link to="/auth/login">{t('Giris')}</Link>{' '}
                </p>
              </div>
            </div>
          ) : (
            <div className="card g-0" border="gray">
              <div className="card-header card-header-title text-color-success">
                {t('SifremiUnuttum')}
              </div>
              <div className="card-body">
                <form onSubmit={(e) => login(e)} className="d-grid">
                  <div className=" mb-3">
                    <label htmlFor="username" className="form-label">
                      {t('KullaniciAdi')}
                    </label>
                    <input
                      className="formControl-text form-control"
                      id="username"
                      type="text"
                      placeholder="User name"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      {t('GeciciSifre')}
                    </label>
                    <input
                      className="formControl-text form-control"
                      id="password"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>

                  <button
                    className="btn btn-full text-white fw-bold fs-5 rounded ls-1 text-uppercase font-monospace bg-color-success"
                    onClick={(e) => login(e)}
                    type="submit"
                    disabled={!isSubmit || requestPending}>
                    {t('Giris')}{' '}
                    {requestPending && (
                      <img
                        src="/images/avatar/loading.gif"
                        alt="avatar"
                        className="rounded-circle img-fluid"
                        style={{ width: '25px' }}
                      />
                    )}
                  </button>
                </form>
                <p
                  className="forgot-password text-left btn btn-light btn-sm mt-2"
                  onClick={() => setTempPassExisted(false)}>
                  {t('Geri')}
                </p>
                <p className="forgot-password text-right">
                  {' '}
                  <Link to="/auth/login"> {t('Giris')}</Link>{' '}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
