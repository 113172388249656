import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../utils/firebase';

async function saveFileToStorage(fileUrl, fileName, file) {
  let downloadURL = null;

  async function handleUpload() {
    const avatarRef = ref(storage, `${fileUrl}/${fileName}`);
    try {
      const snapshot = await uploadBytes(avatarRef, file);
      downloadURL = await getDownloadURL(snapshot.ref);
    } catch (error) {
      console.log(error);
    }
  }

  await handleUpload();

  return { downloadURL };
}

export default saveFileToStorage;
