import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes/router';
import { MenuProvider } from './context/MenuContext';
import { OrderProvider } from './context/OrderContext';
import App from './App'; // Ensure you import your App component
import { MessageProvider } from './context/MesssageContext';
import { DateSelectionProvider } from './context/DateSelectionContext';
import { AuthProvider } from './context/JWTAuthContext';
import { LinkParamsProvider } from './context/LinkParamsContext.';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { StrictMode } from 'react';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StrictMode>
    <LinkParamsProvider>
      <MessageProvider>
        <DateSelectionProvider>
          <AuthProvider>
            <MenuProvider>
              <OrderProvider>
                <BrowserRouter>
                  <Router>
                    <App />
                  </Router>
                </BrowserRouter>
              </OrderProvider>
            </MenuProvider>
          </AuthProvider>
        </DateSelectionProvider>
      </MessageProvider>
    </LinkParamsProvider>
  </StrictMode>
);

reportWebVitals();
