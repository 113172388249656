import  { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useAuth from '../../../features/hooks/useAuth';
import useMenu from '../../../features/hooks/useMenu';
import './bottommenu.css'

export default function BottomMenu() {
    const { t } = useTranslation();
    const { selectedComponentHandler } = useMenu();
    const [currentMenus, setCurrentMenus] = useState("Unknown");
    const { user } = useAuth();

    useEffect(() => {
        if (user) {
            const { authenticationProviderType } = user;
            setCurrentMenus(authenticationProviderType)
        }
    }, [user]);
    if (currentMenus == "Customer" || currentMenus == "Page") {
        return (
            <nav className="navbar fixed-bottom navbar-light bg-white bg-light m-0 shadow-sm d-md-none py-2 bottom-menu">
                <div className="container-fluid d-block p-0 mx-2">
                    <div className='row'>
                        <NavLink to={"/"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="home-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('AnaSayfa')}</button>
                        </NavLink>
                        <NavLink to={"/menu"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="grid-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Menu')}</button>
                        </NavLink>
                        <NavLink to={"/platforms"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="reader-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Platforms')}</button>
                        </NavLink>
                        <NavLink to={"/Kampanya"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="gift-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Kampanya')}</button>
                        </NavLink>
                    </div>
                </div>
            </nav>
        )
    } else if (currentMenus == "Follower2") {
        return (
            <nav className="navbar fixed-bottom navbar-light bg-white bg-light m-0 shadow-sm d-md-none py-2 bottom-menu">
                <div className="container-fluid d-block p-0 mx-2">
                    <div className='row'>
                        <NavLink to={"/"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="grid-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Menu')}</button>
                        </NavLink>
                        <NavLink to={"/dashboard/AracTakip"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="car-sport-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Takipci')}</button>
                        </NavLink>
                        <NavLink to={"/branch"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="earth-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Sube')}</button>
                        </NavLink>
                        <NavLink to={"/Kampanya"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="gift-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Kampanya')}</button>
                        </NavLink>
                    </div>
                </div>
            </nav>
        )
    }
    else {
        return (
            <nav className="navbar fixed-bottom navbar-light bg-white bg-light m-0 shadow-sm d-md-none py-2 bottom-menu">
                <div className="container-fluid d-block p-0 mx-2">
                    <div className='row'>
                        <NavLink to={"/"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="grid-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Menu')}</button>
                        </NavLink>
                        <NavLink to={"/platforms"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="reader-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Platforms')}</button>
                        </NavLink>
                        <NavLink to={"/branch"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="earth-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Sube')}</button>
                        </NavLink>
                        <NavLink to={"/Kampanya"} className='d-flex flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                            <ion-icon name="gift-outline"></ion-icon>
                            <button className="text-secondary btn m-0 p-0" href="#">{t('Kampanya')}</button>
                        </NavLink>
                    </div>
                </div>
            </nav>
        )
    }

}
