import { useEffect, useState, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import saveFileToStorage from '../../../features/helpers/saveFileToStorage';

function UploadPhoto({ image, handleFormChange }) {
  const cropperRef = useRef(null);
  const imageRef = useRef(null);
  const [uploadImageSrc, setUploadImageSrc] = useState('');
  const [uploadImageFile, setUploadImageFile] = useState('');
  const [uploadPending, setUploadPending] = useState(false);

  useEffect(() => {
    if (uploadPending) {
      setUploadPending(false);
    }
  }, [image]);

  const imageSrc = uploadPending && (
    <img
      src="/images/avatar/loading.gif"
      alt="image"
      className="rounded-circle img-fluid"
      style={{ width: '150px' }}
    />
  );

  function handleInitialUpload() {
    let file = imageRef.current.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      setUploadImageSrc(reader.result);
    };
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  async function handleUpload() {
    await setUploadImageFile(false);
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var year = dateObj.getUTCFullYear();
    const fileUrl = `/image/${year}/${month}/${Date.now()}`;
    const fileName = '1.jpg';
    var file = dataURLtoFile(uploadImageFile, 'profil.png');
    setUploadPending(true);

    const { downloadURL } = await saveFileToStorage(fileUrl, fileName, file);
    handleFormChange({
      target: { name: 'image', value: downloadURL }
    });
  }

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setUploadImageFile(
      cropper
        .getCroppedCanvas({
          width: 400,
          height: 600,
          fillColor: '#fff',
          imageSmoothingEnabled: false,
          imageSmoothingQuality: 'medium'
        })
        .toDataURL()
    );
  };

  const handleCrop = () => {
    setUploadImageSrc('');
    handleUpload();
  };

  return (
    <>
      {uploadImageSrc && (
        <div className="d-flex flex-column justify-content-center">
          <Cropper
            src={uploadImageSrc}
            style={{ height: 'auto', width: '100%' }}
            // Cropper.js options
            guides={false}
            crop={onCrop}
            ref={cropperRef}
            viewMode={1}
            aspectRatio={2 / 3}
          />
          <button className="btn btn-sm btn-light" onClick={() => handleCrop()}>
            SEÇ
          </button>
        </div>
      )}
      <div htmlFor="file-input" className="position-relative">
        {imageSrc}
      </div>
      <div className="input-group floating-label-group">
        <input
          type="file"
          id="file-input"
          name="myfile"
          ref={imageRef}
          onChange={(e) => handleInitialUpload(e)}
        />
      </div>
    </>
  );
}

export default UploadPhoto;
