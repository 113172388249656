import { useTranslation } from 'react-i18next';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Scrolling,
  Selection,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import ScrollView from 'devextreme-react/scroll-view';
import '../../../assets/css/devexpress/dx.common.css';
import '../../../assets/css/devexpress/dx.material.blue.light.css';
import { useEffect, useState } from 'react';
import { HorizontalLine } from 'devextreme-react/chart';
import WaitingSpinner from '../../../components/WaitingSpinner';
import useOrderContext from '../../../context/api/useOrderContext';
import Layout from '../../Layouts';
import SiparisDetail from './SiparisDetail';
import useOrder from '../../../features/hooks/useOrder';
import DialogConfirm from '../../../components/DialogConfirm';
import useRoles from '../../../features/helpers/isAdmin';

function SiparisOnay() {
  const { t } = useTranslation();
  const { orderWaitingList, getOrderApprovaList } = useOrder();
  const { GetOrderStatusDefinitionList, SetOrderStatus } = useOrderContext();
  const [statusList, setStatusList] = useState(0);
  const [status, setStatus] = useState(99);
  const [changingStatus, setChangingStatus] = useState('');
  const [changingId, setChangingId] = useState('');
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const { isCustomer } = useRoles();

  useEffect(() => {
    if (isCustomer) {
      getOrderApprovaList();
    }
  }, [isCustomer]);

  const approve = async (id) => {
    await setChangingId(id);
    await setChangingStatus(4);
    setIsShowConfirmDialog(true);
  };

  const cancel = async (id) => {
    await setChangingId(id);
    await setChangingStatus(3);
    setIsShowConfirmDialog(true);
  };

  const handleSubmit = async () => {
    await SetOrderStatus({ Guid: changingId, Status: changingStatus });
    setIsShowConfirmDialog(false);
    await getOrderApprovaList();
  };

  const renderGridButton = (data) => {
    return (
      <>
        <button
          className="btn btn-success btn-sm me-2"
          onClick={() => approve(data.data.guid)}>
          {t('Onay')}
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => cancel(data.data.guid)}>
          {t('Iptal')}
        </button>
      </>
    );
  };

  useEffect(() => {
    async function getDataStatus() {
      const result = await GetOrderStatusDefinitionList();
      setStatusList(result);
    }
    if (!statusList) {
      getDataStatus();
    }
    return () => {
      setStatusList('');
    };
  }, []);

  function selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  function renderGridCell(cellData) {
    return cellData.value ? (
      <div>
        <img className="img-fluid" src={cellData.value}></img>
      </div>
    ) : (
      <div>
        <img className="img-fluid" src="/images/avatar/profil.png"></img>
      </div>
    );
  }

  if (orderWaitingList) {
    return (
      <>
        <Layout>
          <ScrollView direction="horizontal">
            <div className="row gap-2">
              <select
                className="form-select form-select-lg"
                onChange={(event) => setStatus(event.target.value)}
                name="status"
                required
                value={status}>
                <option value={99}> Onay Bekleyen Siparişler </option>
                {(statusList?.length &&
                  statusList.map((status) => {
                    return (
                      <option value={status.code} key={status.code}>
                        {status.name}
                      </option>
                    );
                  })) ||
                  ''}
              </select>
            </div>
            <DataGrid
              id="siparisListesi"
              dataSource={orderWaitingList}
              allowColumnReordering={false}
              scrollingEnabled={false}
              // defaultSelectedRowKeys={0}
              columnAutoWidth={true}
              focusedRowEnabled={true}
              keyExpr="kayitNo"
              onSelectionChanged={(e) => selectionChanged(e)}
              // onContentReady={(e) => contentReady(e)}
            >
              <GroupPanel visible={false} />
              <Grouping autoExpandAll={false} />
              <FilterRow visible={true} />
              <Selection mode={'none'} />
              <HeaderFilter visible={false} />
              <Scrolling preloadEnabled={true} mode={HorizontalLine} />
              <Column
                caption={t('KayitNo')}
                dataField={'kayitNo'}
                allowSorting={false}
                allowFiltering={true}
                allowGrouping={false}
                allowReordering={false}
                width={0}
              />
              <Column
                caption={t('Resim')}
                dataField={'img'}
                cellRender={renderGridCell}
                width={70}
              />
              <Column
                caption={t('SiparisNo')}
                dataField={'evrakNo'}
                width={100}
              />
              <Column
                caption={t('Tutar')}
                dataField={'tutarUsd'}
                format="#,##0.00"
                width={120}
              />
              <Column
                caption={t('KargoUcreti')}
                dataField={'nakliyeFiyati'}
                format="#,##0.00"
                width={100}
              />
              <Column
                caption={t('Toplam')}
                dataField={'yekun'}
                format="#,##0.00"
                width={150}
              />
              <Column
                caption={t('Durum')}
                cellRender={renderGridButton}
                width={220}
              />

              <Column
                caption={t('Firma')}
                dataField={'firmaUnvani'}
                width={150}
              />
              <Column
                caption={t('UrunAdi')}
                dataField={'stokAdi'}
                dataType={'string'}
                width={125}
              />
              <Column
                caption={t('Marka')}
                dataField={'marka'}
                dataType={'string'}
                width={125}
              />
              <Column
                caption={t('Beden')}
                dataField={'beden'}
                dataType={'string'}
                width={100}
              />
              <Column
                caption={t('Renk')}
                dataField={'renk'}
                dataType={'string'}
                width={100}
              />
              <Column
                caption={t('Tarih')}
                dataField={'tarih'}
                dataType={'date'}
                format={'dd.MM.yyyy'}
                width={100}
              />
              <Summary>
                <TotalItem column="cariUnvani" summaryType="count" />
                <TotalItem
                  column="yekun"
                  summaryType="sum"
                  valueFormat="#,###,##0.00"
                />
              </Summary>
              <Pager
                allowedPageSizes={[100, 200, 500, 1000]}
                showPageSizeSelector={true}
              />
              <Paging defaultPageSize={100} />
              <MasterDetail
                enabled={true}
                render={(e) => (
                  <SiparisDetail data={e} statusList={statusList} />
                )}
              />
            </DataGrid>
          </ScrollView>
        </Layout>
        <DialogConfirm
          isShowConfirmDialog={isShowConfirmDialog}
          setIsShowConfirmDialog={setIsShowConfirmDialog}
          setDialogResult={handleSubmit}
        />
      </>
    );
  } else {
    return <WaitingSpinner data={orderWaitingList} />;
  }
}

export default SiparisOnay;
