const deJSON = {
  Tamam: 'Ok',
  Tarih: 'Datum',
  Bugun: 'Heute',
  BuHafta: 'Diese Woche',
  BuAy: 'Diesen Monat',
  BuYil: 'Dieses Jahr',
  SifreEslesmiyor: 'Passwörter stimmen nicht überein',
  SifreDegistir: 'Passwort ändern',
  MevcutSifre: 'Aktuelles Passwort',
  YeniSifre: 'Neues Passwort',
  SifreyiDogrula: 'Passwort bestätigen',
  Kaydet: 'Speichern',
  Geri: 'Zurück',
  SifreTelefonunuzaGonderildi: 'Passwort wurde an Ihr Telefon gesendet',
  SifrenizDahaOnceGonderildi: 'Ihr Passwort wurde bereits gesendet',
  SifremiUnuttum: 'Ich habe mein Passwort vergessen',
  KullaniciAdi: 'Benutzername',
  TelefonVeyaEmail: 'Telefon oder E-Mail',
  Sifirla: 'Zurücksetzen',
  SifremVar: 'Ich habe ein Passwort',
  Giris: 'Anmeldung',
  GecersizTelefon: 'Ungültige Telefonnummer',
  GecersizSifre: 'Ungültiges Passwort',
  AdiSoyadi: 'Vor- und Nachname',
  Email: 'E-Mail',
  Gsm: 'Mobiltelefon',
  UyeOl: 'Registrieren',
  DahaOnceKayitli: 'Bereits registriert',
  Telefon1: 'Telefon 1',
  Telefon2: 'Telefon 2',
  Adres: 'Adresse',
  Sehir: 'Stadt',
  Ulke: 'Land',
  Instagram: 'Instagram',
  Facebook: 'Facebook',
  Baslik: 'Titel',
  Sira: 'Reihenfolge',
  EvrakTipi: 'Dokumenttyp',
  Cinsi: 'Art',
  Tipi: 'Typ',
  AracNo: 'Fahrzeugnummer',
  Aciklama: 'Beschreibung',
  Aciklama1: 'Beschreibung 1',
  Aciklama2: 'Beschreibung 2',
  Borc: 'Schulden',
  Alacak: 'Forderungen',
  Bakiye: 'Kontostand',
  Depo: 'Lager',
  TeslimNoktasi: 'Lieferort',
  SiparisNo: 'Bestellnummer',
  KayitNo: 'Registrierungsnummer',
  Bekleyen: 'Ausstehend',
  Detay: 'Details',
  Yol: 'Straße',
  Saha: 'Bereich',
  Kapi: 'Tür',
  Biten: 'Abgeschlossen',
  TarihTutar: 'Datum Betrag',
  AraToplam: 'Zwischensumme',
  GonderilenHavale: 'Überweisung gesendet',
  KapSayisi: 'Anzahl der Türen',
  Kg: 'Kg',
  M3: 'm³',
  M3Kg: 'm³/Kg',
  CariHesapLimiti: 'Kreditlimit',
  Kodu: 'Code',
  Arama: 'Suche',
  Adi: 'Name',
  Soyadi: 'Nachname',
  Vade: 'Fälligkeit',
  Toplam: 'Gesamt',
  Doviz: 'Währung',
  AnaSayfa: 'Startseite',
  Menu: 'Menü',
  Platforms: 'Plattformen',
  Kampanya: 'Kampagne',
  Takipci: 'Follower',
  Sube: 'Filiale',
  Tr: 'Türkisch',
  En: 'Englisch',
  De: 'Deutsch',
  Ar: 'Arabisch',
  Kr: 'Kurdisch',
  OkunmamisMesajlar: 'Ungelesene Nachrichten',
  Bildirimler: 'Benachrichtigungen',
  TumunuGoruntule: 'Alle anzeigen',
  Hakkinda: 'Über uns',
  FaturaBilgileri: 'Rechnungsinformationen',
  iletisim: 'Kontakt',
  OneriSikayet: 'Vorschlag/Beschwerde',
  Degistir: 'Ändern',
  Telefon: 'Telefon',
  TelefonNumarasi: 'Telefonnummer',
  TumSubeler: 'Alle Filialen',
  Unvani: 'Titel',
  LutfenSehirSeciniz: 'Bitte wählen Sie eine Stadt',
  MusteriSayfasi: 'Kundenbereich',
  Link: 'Link',
  BedenSecin: 'Größe auswählen',
  TekBeden: 'Einheitsgröße',
  Variant: 'Variante',
  Beden: 'Größe',
  Renk: 'Farbe',
  UrunAdi: 'Produktname',
  Miktar: 'Menge',
  Fiyat: 'Preis',
  Tutar: 'Betrag',
  TutarUsd: 'Betrag (USD)',
  KargoUcreti: 'Versandkosten',
  DovizKuru: 'Wechselkurs',
  TeslimTarihi: 'Lieferdatum',
  Resim: 'Bild',
  Durum: 'Status',
  Kargo: 'Versand',
  Firma: 'Unternehmen',
  Marka: 'Marke',
  GumrukUcreti: 'Zollgebühr',
  Duzenle: 'Bearbeiten',
  Sil: 'Löschen',
  Onay: 'Bestätigen',
  SubeListesi: 'Filialliste',
  SiparisListesi: 'Bestellliste',
  YeniSiparis: 'Neue Bestellung',
  SiparisOnay: 'Bestellbestätigung',
  MusteriHesapListesi: 'Kundenkontoliste',
  MusteriListesi: 'Kundenliste',
  TeslimEdildi: 'Geliefert',
  MusteriHesabi: 'Kundenkonto',
  Devir: 'Übertragung',
  _Devir_: 'Übertragung',
  AlisFaturasi: 'Einkaufsrechnung',
  SatisFaturasi: 'Verkaufsrechnung',
  Fatura: 'Rechnung',
  Tahsilat: 'Zahlungseingang',
  Odeme: 'Zahlungsausgang',
  Masraf: 'Ausgaben',
  Cari: 'Kunde',
  CariPersonel: 'Kundenpersonal',
  Kasa: 'Kasse',
  Banka: 'Bank',
  Hizmet: 'Dienstleistung',
  MuhasebeHesabi: 'Accounting Account',
  Personel: 'Personal',
  Demirbas: 'Inventar',
  Ithalat: 'Import',
  Leasing: 'Leasing',
  Kredi: 'Kredit',
  Stok: 'Lager',
  TumSiparisler: 'Alle Bestellungen',
  Beklemede: 'In Bearbeitung',
  KontrolEdiliyor: 'Wird überprüft',
  MusteriOnayinda: 'In Kundenbestätigung',
  MusteriIptali: 'Kundenstornierung',
  Onaylandi: 'Bestätigt',
  Tedarik: 'Beschaffung',
  Hazirlaniyor: 'Wird vorbereitet',
  TurkiyeDepo: 'Türkei Lager',
  YurtDisiKargo: 'Versand Ausland',
  Iptal: 'Storniert',
  Red: 'Abgelehnt',
  Iade: 'Rückgabe',
  HasarliUrun: 'Beschädigte Ware',
  Subede: 'In Filiale',
  Document1: 'Reisepass',
  Document2: 'Personalausweis',
  Dikkat: 'Achtung',
  Onayliyormusunuz: 'Bestätigen Sie',
  Evet: 'Ja',
  Hayir: 'Nein',
  Tahakkuk: 'Anfallen'
};

export default deJSON;
