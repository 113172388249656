import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGeneralContext from '../../../context/api/useGeneralContext';
import useMessage from '../../../features/hooks/useMessage';
import './customerCreate.css';

function CustomerCreatePage({
  isShowCreatePage,
  setIsShowCreatePage,
  customerCode,
  costumerNotFound,
  setCustomerCode,
  setParentFields
}) {
  const { CustomerInsert } = useGeneralContext();
  const { GetCity, GetCountry } = useGeneralContext();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState('');
  const [country, setCountry] = useState('');
  const { messageBox } = useMessage();

  const { t } = useTranslation();

  const fields = {
    fullName: '',
    email: '',
    phone: '',
    address: '',
    city: ''
  };

  const [inputFields, setInputFields] = useState(fields);

  const GetCities = async (Kodu) => {
    const result = await GetCity(Kodu);
    setCities(result);
    setInputFields({ ...inputFields, city: '' });
  };

  useEffect(() => {
    try {
      GetCountry().then((res) => {
        setCountries(res);
        setCountry('612');
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (costumerNotFound) {
      setInputFields({ ...inputFields, phone: customerCode });
    }
  }, [costumerNotFound]);

  useEffect(() => {
    try {
      if (country != '') {
        GetCities(country);
      } else {
        setCities([]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [country]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setInputFields({ ...inputFields, [name]: value });
  };

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = Object.values(inputFields).every((value) => value.length > 0);
    if (!inputFields.city) {
      messageBox(t('LutfenSehirSeciniz'), 'error');
    }
    if (isValid) {
      const result = await CustomerInsert({
        kodu: inputFields.phone,
        unvani: inputFields.fullName,
        email: inputFields.email,
        phone: inputFields.phone,
        address: inputFields.address,
        city: inputFields.city,
        country: country
      });
      if (result) {
        setCustomerCode(inputFields.phone);
        setParentFields({
          target: { name: 'unvani', value: inputFields.fullName }
        });
        setIsShowCreatePage(false);
        setTimeout(() => {
          setInputFields(fields);
        }, 500);
      }
    }
  };

  let showCreatePage = '';
  isShowCreatePage
    ? (showCreatePage = 'show-create-page')
    : (showCreatePage = '');

  const { fullName, email, phone, address, city } = inputFields;

  return (
    <>
      <div
        className={`customer-gray-layout ${showCreatePage}`}
        onClick={() => setIsShowCreatePage(false)}></div>
      <div
        className={`customer-page-container position-absolute left-0 right-0 ${showCreatePage}`}>
        <div className="py-2 px-2 bg-color-primary bg-gradient rounded m-2 gl-page-header-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nav--hamburger"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          <p className="text-center m-0 fs-5">{t('MusteriSayfasi')}</p>
        </div>
        <form onSubmit={(e) => handleSubmit(e)} className="p-2">
          <div className="input-group floating-label-group">
            <input
              type="text"
              name="fullName"
              className="form-control py-2"
              id="fullName"
              value={fullName}
              required
              onChange={(event) => handleFormChange(event)}
              onInput={(e) => toInputUppercase(e)}
            />
            <label className="floating-label">{t('AdiSoyadi')}</label>
          </div>
          <div className="input-group floating-label-group">
            <input
              type="email"
              name="email"
              className="form-control py-2"
              id="email"
              value={email}
              required
              onChange={(event) => handleFormChange(event)}
            />
            <label className="floating-label">{t('Email')}</label>
          </div>
          <div className="input-group floating-label-group">
            <input
              type="text"
              name="phone"
              className="form-control py-2"
              id="phone"
              value={phone}
              required
              onChange={(event) => handleFormChange(event)}
            />
            <label className="floating-label">{t('Telefon')}</label>
          </div>
          <div className="input-group floating-label-group">
            <input
              type="text"
              name="address"
              className="form-control py-2"
              id="address"
              value={address}
              required
              onChange={(event) => handleFormChange(event)}
              onInput={(e) => toInputUppercase(e)}
            />
            <label className="floating-label">{t('Adres')}</label>
          </div>
          <div className="input-group floating-label-group">
            <select
              className="form-select form-select-lg"
              onChange={(event) => setCountry(event.target.value)}
              name="country"
              required
              defaultValue={612}>
              <option value={612}>IRAQ</option>
              {(countries?.length &&
                countries.map((country) => {
                  return (
                    <option value={country.code} key={country.code}>
                      {country.name}
                    </option>
                  );
                })) ||
                ''}
            </select>
            <label className="floating-label">{t('Ulke')}</label>
          </div>
          <div className="input-group floating-label-group">
            <select
              className="form-select form-select-lg"
              onChange={(event) => handleFormChange(event)}
              name="city"
              required
              defaultValue={city}>
              <option key={city.city}>Şehir Seçiniz</option>
              {(cities?.length &&
                cities.map((city) => {
                  return (
                    <option value={city.code} key={city.code}>
                      {city.name}
                    </option>
                  );
                })) ||
                ''}
            </select>
            <label className="floating-label">{t('Sehir')}</label>
          </div>
          <button type="submit" className="btn btn-primary btn-block">
            {t('Kaydet')}
          </button>
        </form>
      </div>
    </>
  );
}

export default CustomerCreatePage;
