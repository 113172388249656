import { useState, useEffect } from 'react';
import './Register.css';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../../features/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import {
  isValidPhoneNumber,
  isValidPassword
} from '../../../features/helpers/isValidPhoneNumber';
import useMessage from '../../../features/hooks/useMessage';
import { Spinner } from 'react-bootstrap';
import DialogConfirm from '../../../components/DialogConfirm';

function Register() {
  const { messageBox } = useMessage();
  const [FullName, setFullName] = useState('');
  const [Email, setEmail] = useState('');
  const [MobilePhones, setMobilePhones] = useState('');
  const [Password, setPassword] = useState('');
  let navigate = useNavigate();
  const userInfo = { FullName, Email, MobilePhones, Password };
  const { t } = useTranslation();
  const { register, isAuthenticated } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);

  // async function submitHandler(e) {
  //   e.preventDefault();
  //   if (isSubmitting) return;
  //   setIsSubmitting(true);
  //   if (!isValidPhoneNumber(MobilePhones)) {
  //     messageBox(t('GecersizTelefon'), 'error');
  //     setIsSubmitting(false);
  //     return;
  //   }
  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    // let isValid = Object.values(inputFields).every((value) => {
    //   return String(value).length > 0;
    // });

    if (!isValidPhoneNumber(MobilePhones)) {
      messageBox(t('GecersizTelefon'), 'error');
      setIsSubmitting(false);
      return;
    }
    if (!isValidPassword(Password)) {
      messageBox(t('GecersizSifre'), 'error');
      setIsSubmitting(false);
      return;
    }
    await register({ ...userInfo, UserName: MobilePhones });
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  return (
    <div className="container px-4 px-lg-0 mb-4">
      <div className="text-center mt-4">
        <img
          src="/images/icons/logo2.png"
          className="img-fluid logo"
          width={342}
          height={162}></img>
      </div>
      <div className="row justify-content-center d-grid d-flex bg-sm-image">
        <div className="mt-4 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h3>{t('KayitOl')}</h3>
            </div>
            <div className="card-body pt-0 pb-2 ps-3 pe-3">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setIsShowConfirmDialog(true);
                  // onSubmit={(e) => handleSubmit(e)}
                }}>
                <div className="form-group">
                  <label className="mt-2">{t('AdiSoyadi')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    value={FullName}
                    onChange={(e) => {
                      setFullName(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="mt-2">{t('Email')}</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                    value={Email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                  />
                </div>
                {/* <div className="form-group">
                  <label className="mt-2">{t('KullaniciAdi')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="User Name"
                    value={UserName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                    required
                  />
                </div> */}
                <div className="form-group">
                  <label className="mt-2">{t('Gsm')}</label>
                  <input
                    type="number"
                    className={`form-control ${
                      !isValidPhoneNumber(MobilePhones) && 'is-not-valid'
                    }`}
                    placeholder="Örn: 7501234567"
                    value={MobilePhones}
                    required
                    onChange={(e) => {
                      setMobilePhones(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="mt-2">{t('Sifre')}</label>
                  <input
                    type="password"
                    className={`form-control ${
                      !isValidPassword(Password) && 'is-not-valid'
                    }`}
                    placeholder="Enter password"
                    value={Password}
                    minLength="6"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn-submit btn btn-primary btn-block bg-color-success position-relative"
                  disabled={isSubmitting}>
                  {t('UyeOl')}
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="position-absolute top-50 start-50 translate-middle"
                    />
                  )}
                </button>
                <div className="signin">
                  <p className="forgot-password text-right fs-7">
                    {t('DahaOnceKayitli')}{' '}
                    <Link to="/auth/login">{t('Giris')}</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <DialogConfirm
        isShowConfirmDialog={isShowConfirmDialog}
        setIsShowConfirmDialog={setIsShowConfirmDialog}
        setDialogResult={() => {
          setIsShowConfirmDialog(false);
          handleSubmit();
        }}
      />
    </div>
  );
}

export default Register;
