const trJSON = {
  Tamam: 'Tamam',
  Tarih: 'Tarih',
  Bugun: 'Bugün',
  BuHafta: 'Bu Hafta',
  BuAy: 'Bu Ay',
  BuYil: 'Bu Yıl',
  SifreEslesmiyor: 'Şifreler eşleşmiyor',
  SifreDegistir: 'Şifre Değiştir',
  MevcutSifre: 'Mevcut Şifre',
  YeniSifre: 'Yeni Şifre',
  SifreyiDogrula: 'Şifreyi Doğrula',
  Kaydet: 'Kaydet',
  Geri: 'Geri',
  SifreTelefonunuzaGonderildi: 'Şifre telefonunuza gönderildi',
  SifrenizDahaOnceGonderildi: 'Şifreniz daha önce gönderildi',
  SifremiUnuttum: 'Şifremi Unuttum',
  KullaniciAdi: 'Kullanıcı Adı',
  TelefonVeyaEmail: 'Telefon veya Email',
  Sifirla: 'Sıfırla',
  SifremVar: 'Şifrem Var',
  Giris: 'Giriş',
  GeciciSifre: 'Geçici Şifre',
  Sifre: 'Şifre',
  KayitOl: 'Kayıt Ol',
  GecersizTelefon: 'Geçersiz Telefon',
  GecersizSifre: 'Geçersiz Şifre',
  AdiSoyadi: 'Adı Soyadı',
  Email: 'Email',
  Gsm: 'Gsm',
  UyeOl: 'Üye Ol',
  DahaOnceKayitli: 'Daha Önce Kayıtlı',
  Telefon1: 'Telefon 1',
  Telefon2: 'Telefon 2',
  Adres: 'Adres',
  Sehir: 'Şehir',
  Ulke: 'Ülke',
  Instagram: 'Instagram',
  Facebook: 'Facebook',
  Baslik: 'Başlık',
  Sira: 'Sıra',
  EvrakTipi: 'Evrak Tipi',
  Cinsi: 'Cinsi',
  Tipi: 'Tipi',
  AracNo: 'Araç No',
  Note: 'Not',
  Aciklama: 'Açıklama',
  Aciklama1: 'Açıklama 1',
  Aciklama2: 'Açıklama 2',
  Borc: 'Borç',
  Alacak: 'Alacak',
  Bakiye: 'Bakiye',
  Depo: 'Depo',
  TeslimNoktasi: 'Teslim Noktası',
  SiparisNo: 'Sipariş No',
  KayitNo: 'Kayıt No',
  Bekleyen: 'Bekleyen',
  Detay: 'Detay',
  Yol: 'Yol',
  Saha: 'Saha',
  Kapi: 'Kapı',
  Biten: 'Biten',
  TarihTutar: 'Tarih Tutar',
  AraToplam: 'Ara Toplam',
  GonderilenHavale: 'Gönderilen Havale',
  KapSayisi: 'Kap Sayısı',
  Kg: 'Kg',
  M3: 'M3',
  M3Kg: 'M3/Kg',
  CariHesapLimiti: 'Cari Hesap Limiti',
  Kodu: 'Kodu',
  Arama: 'Arama',
  Adi: 'Adı',
  Soyadi: 'Soyadı',
  Vade: 'Vade',
  Toplam: 'Toplam',
  Doviz: 'Döviz',
  AnaSayfa: 'Ana Sayfa',
  Menu: 'Menü',
  Platforms: 'Platforms',
  Kampanya: 'Kampanya',
  Takipci: 'Takipçi',
  Sube: 'Şube',
  Tr: 'Türkçe',
  En: 'ingilizce',
  De: 'Almanca',
  Ar: 'Arapça',
  Kr: 'Kürtçe',
  OkunmamisMesajlar: 'Okunmamış Mesajlar',
  Bildirimler: 'Bildirimler',
  TumunuGoruntule: 'Tümünü Görüntüle',
  Hakkinda: 'Hakkında',
  FaturaBilgileri: 'Fatura Bilgileri',
  iletisim: 'İletişim',
  OneriSikayet: 'Öneri Şikayet',
  Degistir: 'Değiştir',
  Telefon: 'Telefon',
  TelefonNumarasi: 'Telefon Numarası',
  TumSubeler: 'Tüm Şubeler',
  Unvani: 'Ünvanı',
  LutfenSehirSeciniz: 'Lütfen Şehir Seçiniz',
  MusteriSayfasi: 'Müşteri Sayfası',
  Link: 'Link',
  BedenSecin: 'Beden Seçin',
  TekBeden: 'Tek Beden',
  Variant: 'Variant',
  Beden: 'Beden',
  Renk: 'Renk',
  UrunAdi: 'Ürün Adı',
  Miktar: 'Miktar',
  Fiyat: 'Fiyat',
  Tutar: 'Tutar',
  TutarUsd: 'Tutar USD',
  DovizKuru: 'Döviz Kuru',
  Kur: 'Kur',
  TeslimTarihi: 'Teslim Tarihi',
  Resim: 'Resim',
  Durum: 'Durum',
  Kargo: 'Kargo',
  Firma: 'Firma',
  Marka: 'Marka',
  KargoUcreti: 'Kargo Ücreti',
  GumrukUcreti: 'Gümrük Ücreti',
  Duzenle: 'Düzenle',
  Sil: 'Sil',
  Onay: 'Onayla',
  Iptal: 'İptal',
  SubeListesi: 'Şube Listesi',
  SiparisListesi: 'Sipariş Listesi',
  YeniSiparis: 'Yeni Sipariş',
  SiparisOnay: 'Sipariş Onay',
  MusteriHesapListesi: 'Müşteri Hesap Listesi',
  MusteriListesi: 'Müşteri Listesi',
  Subede: 'Şubede',
  TeslimEdildi: 'Teslim Edildi',
  MusteriHesabi: 'Müşteri Hesabı',
  Devir: 'Devir',
  _Devir_: 'Devir',
  AlisFaturasi: 'Alış Faturası',
  SatisFaturasi: 'Satış Faturası',
  Fatura: 'Fatura',
  Tahsilat: 'Tahsilat',
  Odeme: 'Ödeme',
  Masraf: 'Masraf',
  Cari: 'Cari',
  CariPersonel: 'Cari Personel',
  Kasa: 'Kasa',
  Banka: 'Banka',
  Hizmet: 'Hizmet',
  MuhasebeHesabı: 'Muhasebe Hesabı',
  Personel: 'Personel',
  Demirbas: 'Demirbaş',
  İthalat: 'İthalat',
  Leasing: 'Leasing',
  Kredi: 'Kredi',
  Stok: 'Stok',
  TumSiparisler: 'Tüm Siparişler',
  Beklemede: 'Beklemede',
  KontrolEdiliyor: 'Kontrol Ediliyor',
  MusteriOnayinda: 'Müşteri Onayında',
  MusteriIptali: 'Müşteri İptali',
  Onaylandi: 'Onaylandı',
  Tedarik: 'Tedarik',
  Hazirlaniyor: 'Hazırlanıyor',
  TurkiyeDepo: 'Türkiye Depo',
  YurtDisiKargo: 'Yurt Dışı Kargo',
  Red: 'Red',
  Iade: 'İade',
  HasarliUrun: 'Hasarlı Ürün',
  Document1: 'Pasaport',
  Document2: 'Kimlik Kartı',
  Dikkat: 'Dikkat',
  Onayliyormusunuz: 'Onaylıyormusunuz',
  Evet: 'Evet',
  Hayir: 'Hayır',
  Tahakkuk: 'Tahakkuk'
};
export default trJSON;
