import { useTranslation } from 'react-i18next';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Scrolling,
  Selection,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import ScrollView from 'devextreme-react/scroll-view';
import '../../../assets/css/devexpress/dx.common.css';
import '../../../assets/css/devexpress/dx.material.blue.light.css';
import { useEffect, useState } from 'react';
import WaitingSpinner from '../../../components/WaitingSpinner';
import useOrderContext from '../../../context/api/useOrderContext';
import Layout from '../../Layouts';
import useDate from '../../../features/hooks/useDate';
import SiparisDetail from './SiparisDetail';
import useAuth from '../../../features/hooks/useAuth';
import { useLocation } from 'react-router-dom';

function SiparisListesi() {
  const { t } = useTranslation();
  const { GetOrderList, GetCustomerOrderStatusDefinitionList } =
    useOrderContext();
  const [data, setData] = useState('');
  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState(null);
  const { isAuthenticated } = useAuth();
  const { ilkTarih, sonTarih } = useDate();
  const location = useLocation();

  let statusCode = location.state?.statusCode || null;

  useEffect(() => {
    if (statusCode && isAuthenticated) {
      setStatus(statusCode);
    } else {
      setStatus(99);
    }
    return () => {
      setStatusList('');
    };
  }, [statusCode, isAuthenticated]);

  useEffect(() => {
    async function getDataStatus() {
      const result = await GetCustomerOrderStatusDefinitionList();
      setStatusList(result);
    }
    async function getData(_IlkTarih, _SonTarih) {
      const result = await GetOrderList(_IlkTarih, _SonTarih, status);
      setData(result);
    }
    if (!statusList.length && isAuthenticated) {
      getDataStatus();
    }
    if (isAuthenticated && status) {
      getData(ilkTarih, sonTarih);
    }
    return () => {
      setData('');
    };
  }, [ilkTarih, sonTarih, status, isAuthenticated, statusList.length]);

  function selectionChanged(e) {
    e.component.collapseAll(-1);
    if (e.currentSelectedRowKeys.length) {
      e.component.expandRow(e.currentSelectedRowKeys[0]);
    }
  }

  function renderGridCell(cellData) {
    return cellData.value ? (
      <div>
        <img className="img-fluid" src={cellData.value} alt="Ürün resmi"></img>
      </div>
    ) : (
      <div>
        <img
          className="img-fluid"
          src="/images/avatar/profil.png"
          alt="Profil resmi"></img>
      </div>
    );
  }

  const cellPrepared = (e) => {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'cariUnvani') {
        const tahakukRenk = e.data.tahakuk ? 'LightGreen' : '';
        const tahsilatRenk = e.data.tahsilat ? 'blue' : '';
        e.cellElement.style.cssText =
          'color:' +
          `${tahsilatRenk}` +
          ';background-color:' +
          `${tahakukRenk}`;
      }
    }
  };

  if (data) {
    return (
      <Layout>
        <ScrollView direction="horizontal">
          <div className="row gap-2">
            <label className="floating-label">{t('Durum')}</label>
            <select
              className="form-select form-select-lg"
              onChange={(event) => setStatus(parseInt(event.target.value))}
              name="status"
              required
              value={status}>
              <option value={99}> {t('TumSiparisler')} </option>
              {statusList?.map((status) => (
                <option value={status.code} key={status.code}>
                  {t(status.name)}
                </option>
              ))}
            </select>
          </div>
          <DataGrid
            id="siparisListesi"
            dataSource={data}
            allowColumnReordering={false}
            columnAutoWidth={true}
            focusedRowEnabled={true}
            keyExpr="kayitNo"
            onSelectionChanged={(e) => selectionChanged(e)}
            onCellPrepared={cellPrepared}>
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={false} />
            <FilterRow visible={true} />
            <Selection mode={'none'} />
            <HeaderFilter visible={false} />
            <Scrolling preloadEnabled={true} mode="horizontal" />
            <Column caption={t('KayitNo')} dataField={'kayitNo'} width={0} />
            <Column
              caption={t('Resim')}
              dataField={'img'}
              cellRender={renderGridCell}
              width={70}
            />
            <Column caption={t('Durum')} dataField={'durumAdi'} width={100} />
            <Column caption={t('Adi')} dataField={'cariUnvani'} width={200} />
            <Column
              caption={t('Odeme')}
              dataField={'tahsilat'}
              dataType={'boolean'}
              width={100}
            />
            <Column
              caption={t('SiparisNo')}
              dataField={'evrakNo'}
              width={100}
            />
            <Column
              caption={t('Tutar')}
              dataField={'tutarUsd'}
              format="#,##0.00"
              width={120}
            />
            <Column
              caption={t('Kargo')}
              dataField={'nakliyeFiyati'}
              format="#,##0.00"
              width={100}
            />
            <Column
              caption={t('Toplam')}
              dataField={'yekun'}
              format="#,##0.00"
              width={150}
            />
            <Column caption={t('AracNo')} dataField={'aracNo'} width={100} />
            <Column
              caption={t('Aciklama')}
              dataField={'aciklama'}
              width={150}
            />
            <Column
              caption={t('Firma')}
              dataField={'firmaUnvani'}
              width={100}
            />
            <Column caption={t('UrunAdi')} width={125} />
            <Column
              caption={t('Marka')}
              dataField={'marka'}
              dataType={'string'}
              width={125}
            />
            <Column
              caption={t('Beden')}
              dataField={'beden'}
              dataType={'string'}
              width={100}
            />
            <Column
              caption={t('Renk')}
              dataField={'renk'}
              dataType={'string'}
              width={100}
            />
            <Column
              caption={t('Tahsilat')}
              dataField={'tahsilat'}
              dataType={'boolean'}
              width={100}
            />
            <Column
              caption={t('Tarih')}
              dataField={'tarih'}
              dataType={'date'}
              format={'dd.MM.yyyy'}
              width={100}
            />
            <Summary>
              <TotalItem column="cariUnvani" summaryType="count" />
              <TotalItem
                column="yekun"
                summaryType="sum"
                valueFormat="#,###,##0.00"
              />
            </Summary>
            <Pager
              allowedPageSizes={[100, 200, 500, 1000]}
              showPageSizeSelector={true}
            />
            <Paging defaultPageSize={100} />
            <MasterDetail
              enabled={true}
              render={(e) => <SiparisDetail data={e} statusList={statusList} />}
            />
          </DataGrid>
        </ScrollView>
      </Layout>
    );
  } else {
    return <WaitingSpinner data={data} />;
  }
}

export default SiparisListesi;
