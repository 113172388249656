import { useTranslation } from 'react-i18next';
import './dialogConfirm.css';

export default function DialogConfirm({
  isShowConfirmDialog,
  setIsShowConfirmDialog,
  setDialogResult
}) {
  const { t } = useTranslation();

  return (
    <div
      className={`position-fixed top-0 start-0 end-0 bottom-0 justify-content-center align-items-center dialog-confirm-container ${
        isShowConfirmDialog ? 'show-dialog' : ''
      }`}>
      <div
        className={`position-fixed top-0 start-0 end-0 bottom-0 ${
          isShowConfirmDialog ? 'show-dialog' : ''
        }`}
        onClick={() => setIsShowConfirmDialog(false)}></div>
      <div className="bg-white p-4 rounded-3 gl-z-1">
        <h3 className="text-center">{t('Dikkat')}</h3>
        <p className="text-secondary text-center text-black">
          {t('Onayliyormusunuz')}?
        </p>
        <div className="d-flex gap-3">
          <button
            className="btn btn-success fw-bold at-dialog-confirm-btn"
            onClick={() => setDialogResult(true)}>
            {t('Evet')}
          </button>
          <button
            className="btn btn-danger fw-bold at-dialog-confirm-btn"
            onClick={() => setIsShowConfirmDialog(false)}>
            {t('Hayir')}
          </button>
        </div>
      </div>
    </div>
  );
}
