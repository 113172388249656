import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOrderContext from '../../../context/api/useOrderContext';
import './order-status-tracking.css';

function OrderStatusTracking({ data }) {
  // const status = data;
  const status = data;
  const { GetOrderStatusContentList } = useOrderContext();
  // const status = data;
  // status1 ? showNotificationsClass = "show-notifications" : showNotificationsClass = "";
  const [statusList, setStatusList] = useState('');

  const { t } = useTranslation();
  useEffect(() => {
    async function getDataStatus() {
      const result = await GetOrderStatusContentList(status);
      setStatusList(result);
    }
    if (!statusList) {
      getDataStatus();
    }
    return () => {
      setStatusList('');
    };
  }, [status]);

  return (
    <div className="main_container tracking-max-width">
      <div className="padding-bottom-3x mb-1">
        <div className="card mb-3 overflow-auto">
          <div className="card-body pt-3 px-0">
            <div className="steps d-flex flex-sm-nowrap padding-top-2x padding-bottom-1x">
              {/* completed */}
              {statusList &&
                statusList.map((item) => {
                  return item.isVisible == 1 ? (
                    <div
                      className={`step ${1 <= status ? 'completed' : ''}`}
                      key={item.code}>
                      <div className="step-icon-wrap">
                        <div className="step-icon">
                          <img
                            src={item.img}
                            width={36}
                            height={36}
                            className={`step-img ${
                              1 <= status ? 'gl-filter-white' : ''
                            }`}
                          />
                        </div>
                      </div>
                      <p className="step-title">{t(item.name)}</p>
                    </div>
                  ) : (
                    ''
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OrderStatusTracking;
