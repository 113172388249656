const isValidPhoneNumber = (phoneNumber) => {
  // validate phone number
  if (phoneNumber[0] === '0') {
    return false;
  }
  const re = /^\d{10}$/;
  return re.test(phoneNumber);
}

const isValidPassword = (password) => {
  // validate password
  const minValue = 6;
  const passwordLength = password.length;
  if (passwordLength < minValue) {
    return false;
  }

  return true;
}

export { isValidPhoneNumber, isValidPassword };