import useMessage from '@/features/hooks/useMessage';
import apiClient from '@/utils/axios';

export default function useGeneralContext() {
  const { messageBox } = useMessage();

  const GetCity = async (Kodu) => {
    try {
      const response = await apiClient.get(`/api/App/GetCity?Kodu=${Kodu}`);
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetCurrency = async (code = 'TRY') => {
    try {
      // code değeri boş ise "TL" olarak ayarlanıyor
      const currencyCode = code || 'TRY';
      const response = await apiClient.get(`/api/Siparis/GetDovizKuru`, {
        params: { code: currencyCode }
      });

      if (response.data.data[0]) {
        return parseFloat(response.data.data[0].value).toFixed(4);
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err.message, 'error');
    }
  };
  const GetCustomerByName = async (Code) => {
    try {
      const response = await apiClient.post(`/api/siparis/GetCustomerByName`, {
        Code
      });
      console.log(response);
      const data = response.data.data[0];
      return data;
    } catch (err) {
      messageBox('Kayıt Bulunamadı', 'error');
    }
  };

  const CustomerInsert = async (data) => {
    try {
      const response = await apiClient.post(`/api/app/customerInsert`, data);
      if (response.data.data[0].sonuc) {
        messageBox('Başarılı', 'success');
        return response.data.data[0].sonuc;
      } else {
        if (response.data.data[0].message) {
          messageBox(response.data.data[0].message, 'error');
        } else {
          messageBox('Hata Oluştu', 'error');
        }
      }
      // const data = response.data.data[0];
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetSupplier = async (Country = 'TRY') => {
    try {
      const response = await apiClient.get(
        `/api/Siparis/GetSupplier?Country=` + Country
      );
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetCountry = async () => {
    try {
      const response = await apiClient.get(`/api/App/GetCountry`);
      if (response.data.data) {
        return response.data.data;
      } else {
        messageBox('Hata Oluştu', 'error');
      }
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const SetCustomerCreditLimit = async (Info) => {
    try {
      const response = await apiClient.post('/api/App/SetCustomerCreditLimit', {
        code: Info.code,
        total: Info.total,
        expiry: Info.expiry,
        currency: Info.currency
      });
      const data = response.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetCustomerByInfoFromMikro = async (Code) => {
    try {
      const response = await apiClient.get(
        '/api/App/GetCustomerByInfoFromMikro?Code=' + Code
      );
      const data = response.data.data;
      return data[0];
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetOrderBalance = async () => {
    try {
      const response = await apiClient.get('/api/Siparis/GetOrderBalance');
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const SetUserDocumantImage1 = async (adres) => {
    try {
      const response = await apiClient.post('/api/App/SetUserDocumantImage1', {
        Adres: adres
      });
      const data = response.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const SetUserDocumantImage2 = async (adres) => {
    try {
      const response = await apiClient.post('/api/App/SetUserDocumantImage2', {
        Adres: adres
      });
      const data = response.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  return {
    GetCity,
    GetCountry,
    GetCustomerByName,
    CustomerInsert,
    GetSupplier,
    GetCurrency,
    SetCustomerCreditLimit,
    GetOrderBalance,
    SetUserDocumantImage1,
    SetUserDocumantImage2,
    GetCustomerByInfoFromMikro
  };
}
